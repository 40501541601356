import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@shopify/polaris/build/esm/styles.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import en from '@shopify/polaris/locales/en.json';
import './i18n';
import AppProvider from './Shared/Providers/AppProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
   <AppProvider i18n={en}>
        <App/>
    </AppProvider>
);


