import { useCallback, useContext } from 'react';
import {
    KeyboardKey,
    LegacyStack,
    Text,
    VerticalStack,
} from '@shopify/polaris';
import { Kbd } from '@chakra-ui/react'
import styles from './CustomizeSection.module.css';
import { PageData } from '../../../../../ContextAPI/Context';
import { PlatformType, QueryParams } from '../../../../../Constant';
import { IS_LOCAL, PLATFORM } from '../../../../../config';
import { t } from 'i18next';

const CheckoutEditorType = {
    CHECKOUT: 'information',
    POST_PURCHASE: 'information'
}

const CustomizeSection = () => {
    const { setHelpModalData, systemSetting, helpLinks } = useContext(PageData);

    const isMacOS = /mac/i.test(navigator.platform) ? true : false;

    const handleClick = () => {
        setHelpModalData(helpLinks.helpVideoButton);
    }

    const onGoToEditor = useCallback(() => {
        const params = window.location.search;
        const preview = new URLSearchParams(params).get(QueryParams.Type) || 'CHECKOUT';
        const queryParams = `?page=${CheckoutEditorType[preview]}`;
        const storeUrl = IS_LOCAL ? systemSetting.storeUrl : systemSetting.domain

        window.open(`https://${storeUrl}/admin/settings/checkout/editor${queryParams}`, "_blank");
    },[systemSetting]);

    return (
        <div className={styles.customize_section}>
            <section>
                <div className={styles.instruction_section}>
                    <div className={styles.icon}>
                        <img src={'assets/instruction.svg'} alt='instruction' />
                    </div>
                    <h2>
                        {t('customize_your_widgets')}
                    </h2>
                    <p className={styles.sub_text_wrap}>
                        <span className={styles.sub_text}>
                           {t('select_a_widget_or_block_in_the_sidebar_to_start.')}
                        </span>
                    </p>
                </div>
            </section>

            <section>
                <div className={styles.keyboard_section}>
                    {PLATFORM === PlatformType.Wix ?
                        <>
                            <h2>{t('keyboard_shortcuts')}</h2>

                            <div className={styles.keyboard_keys}>
                                <Kbd>
                                    {isMacOS ? '⌘' : 'Ctrl'}
                                </Kbd>
                                <Kbd>Z</Kbd>
                                <p>{t('undo')}</p>
                            </div>

                            <div className={styles.keyboard_keys}>
                                <Kbd>
                                    {isMacOS ? '⌘' : 'Ctrl'}
                                </Kbd>
                                <Kbd>Y</Kbd>
                                <p>{t('redo')}</p>
                            </div>

                            <div className={styles.keyboard_keys}>
                                <Kbd>
                                    {isMacOS ? '⌘' : 'Ctrl'}
                                </Kbd>
                                <Kbd>S</Kbd>
                                <p>{t('save')}</p>
                            </div>
                        </>
                        :
                        <VerticalStack gap='3'>
                            <h2>{t('keyboard_shortcuts')}</h2>

                            <LegacyStack spacing='extraTight' alignment='baseline'>
                                <KeyboardKey>
                                    {isMacOS ? '⌘' : 'Ctrl'}
                                </KeyboardKey>
                                <KeyboardKey>Z</KeyboardKey>
                                <p className={styles.key_width}>{t('undo')}</p>
                            </LegacyStack>

                            <LegacyStack spacing='extraTight' alignment='baseline'>
                                <KeyboardKey>
                                    {isMacOS ? '⌘' : 'Ctrl'}
                                </KeyboardKey>
                                <KeyboardKey>Y</KeyboardKey>
                                <p className={styles.key_width}>{t('redo')}</p>
                            </LegacyStack>

                            <LegacyStack spacing='extraTight' alignment='baseline'>
                                <KeyboardKey>
                                    {isMacOS ? '⌘' : 'Ctrl'}
                                </KeyboardKey>
                                <KeyboardKey>S</KeyboardKey>
                                <p className={styles.key_width}>{t('save')}</p>
                            </LegacyStack>
                        </VerticalStack>
                    }

                </div>
            </section >

            {PLATFORM === PlatformType.Shopify &&
                <section className={styles.links_wrapper}>
                    <div className={styles.instruction_section}>
                        <VerticalStack >
                            <p>
                                <Text as='span' color='subdued'>
                                    <span className={styles.info_text}> {t('place_your_slots_in_the_shopify_editor')} <a href='#' target rel='noreferrer' className={styles.editor_link} onClick={onGoToEditor}> {t('go_to_shopify_checkout_editor')} </a></span>
                                </Text>
                            </p>
                        </VerticalStack>
                    </div>
                </section>
            }

            <section className={styles.links_container}>
                <div className={styles.instruction_section} >
                    <p>
                        <span onClick={handleClick} className={styles.editor_link}> {t('learn_more_about_reconvert_editor.')} </span>
                    </p>
                </div>
            </section>
        </div >
    );
};

export default CustomizeSection;
