import React, { Fragment, useCallback, } from 'react';
import styles from './FieldGroup.module.css';
import TextInput from '../TextInput';
import NumberInput from '../NumberInput';

function FieldGroup(props) {
    const { onChange, label, value, fields, fieldsType } = props

    const handleChange = useCallback((e, target) => {
        let data = { ...value, [target]: e }
        onChange(data);
    }, [onChange, value]);

    return (
        <div>
            <div className={styles.label_wrap}>{label}</div>
            <div className={styles.fields_wrap}>
                {fields.map((item) => {
                    return (
                        <Fragment key={item.target}>
                            {fieldsType === 'textField' ?
                                (
                                    <div>
                                        <TextInput
                                            label={item.label}
                                            value={value?.[item.target]}
                                            onChange={e => handleChange(e, item.target)}
                                            placeholder={item.placeholder || item.label}
                                            inputClassName={styles.input_field}
                                        />
                                    </div>
                                )
                                :
                                (
                                    <div>
                                        <NumberInput
                                            label={item.label}
                                            min={item?.minValue}
                                            value={value?.[item.target]}
                                            onChange={e => handleChange(e, item.target)}
                                            inputClassName={styles.input_field}
                                            max={item?.maxValue}
                                        />
                                    </div>
                                )
                            }
                        </Fragment >
                    )
                })}
            </div>
        </div>
    );
}


export default FieldGroup;
