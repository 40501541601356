import React from 'react';
import { Switch as ChakraSwitch } from '@chakra-ui/react';

import styles from './Switch.module.css';
import { PLATFORM } from '../../../config';
import { PlatformType } from '../../../Constant';

const Switch = ({ isOn, handleToggle }) => {

  return (
    <>
      {PLATFORM === PlatformType.Wix ?
        <ChakraSwitch
          colorScheme='teal'
          size={'md'}
          className={styles.chakra_switch}
          isChecked={isOn}
          onChange={handleToggle}
        />
        :
        <>
          <input
            checked={isOn}
            onChange={handleToggle}
            className={styles.react_switch_checkbox}
            id={`react-switch-new`}
            type='checkbox'
          />
          <label
            className={`${styles.react_switch_label} ${isOn ? styles.switch_on : styles.switch_off}`}
            htmlFor={`react-switch-new`}
          >
            <span className={styles.react_switch_button} />
          </label>
        </>
      }
    </>
  )
};

export default Switch;