import React from 'react';
import { Icon, Tooltip as PolarisTooltip } from '@shopify/polaris';
import { QuestionMarkMinor } from '@shopify/polaris-icons';
import { Tooltip as ChakraTooltip } from '@chakra-ui/react'
import { QuestionIcon } from '@chakra-ui/icons';

import styles from './Tooltip.module.css';
import { PLATFORM } from '../../../config';
import { PlatformType } from '../../../Constant';

function Tooltip({ tooltipText, rootStyles, children }) {
   
    return (
        <div style={{ ...rootStyles }}>
            {PLATFORM === PlatformType.Wix ?
                <ChakraTooltip hasArrow label={tooltipText} placement='top'>
                    <span className={styles.tooltip_icon}>
                        {children ? children : <QuestionIcon width={'16px'} height={'16px'} />}
                    </span>
                </ChakraTooltip>
                :
                <PolarisTooltip content={tooltipText} preferredPosition='above'>
                    <span className={styles.tooltip_icon}>
                        {children ? children : <Icon source={QuestionMarkMinor} color='base' />}
                    </span>
                </PolarisTooltip>
            }
        </div>
    );
}

export default Tooltip;
