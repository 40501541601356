import React, { useContext, useEffect, useMemo } from 'react';
import styles from './MultiSelectDropDown.module.css';
import Label from '../Label';
import { t } from 'i18next';
import { PageData } from '../../../ContextAPI/Context';
import Select from 'react-select';

const MultiSelectDropdown = (props) => {
    const { showTooltip, label, tooltipText, helpText, options, onChange, value, excludeOptions=[] } = props;
    const { setHelpModalData, helpLinks } = useContext(PageData);
    useEffect(() => {
        const elements = document.getElementsByName('HELP_TEXT');

        for (const element of elements) {
            element.addEventListener('click', handleHelp);
        }

        return () => {
            for (const element of elements) {
                element.removeEventListener('click', handleHelp);
            }
        };
    }, []);

    const handleHelp = (event) => {
        const helpKey = event.target.getAttribute('data-help-key');
        setHelpModalData(helpLinks[helpKey]);
    };

    const translatedOptions = useMemo(() => {
        if (excludeOptions?.length) {
            return options?.filter(item => !excludeOptions.includes(item.label)).map(item => {
                return { ...item, label: t(item.label) }
            })
        } else {
            return options?.map(item => {
                return { ...item, label: t(item.label) }
            })

        }
    }, [options, excludeOptions]);

    const optionHelpText = useMemo(() => {
        const option = options.find(item => item.value === value);
        return option?.helpText ? option.helpText : '';
    }, [options, value]);

    const valueWithLabel = useMemo(()=> {
       return Array.isArray(value) ? value?.map(data=> translatedOptions.find(_=> _.value === data)) : []
    },[translatedOptions, value])
    
    return (
        <div className={styles.input_wrap}>

            {label && <Label label={t(label)} tooltipText={tooltipText} showTooltip={showTooltip} />}

            <Select
                value={valueWithLabel}
                isMulti
                name="colors"
                options={translatedOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(value) => onChange(value.map(data => data.value))}
            />

            {optionHelpText && <div className={styles.help_text} dangerouslySetInnerHTML={{ __html: t(optionHelpText) }} />}
            {helpText && <div className={styles.help_text} dangerouslySetInnerHTML={{ __html: t(helpText) }} />}
        </div>
    )
}

export default MultiSelectDropdown;