import React, { useEffect } from 'react';
import { Toast as PolarisToast } from '@shopify/polaris';
import { useToast } from '@chakra-ui/react';
import { PLATFORM } from '../../../config';
import { PlatformType, Toast_Message_Type } from '../../../Constant';

const DURATION_MILLISECOND = 3000;

const Toast = ({ message, messageType, onDismiss }) => {
    const toast = useToast();

    useEffect(() => {
        if (message && PLATFORM === PlatformType.Wix) {
            toast({
                title: message,
                status: messageType === Toast_Message_Type.Success ? 'success' : 'error',
                duration: DURATION_MILLISECOND,
                isClosable: true,
                variant: 'subtle',
                onCloseComplete: onDismiss
            });
        }
    }, [message, messageType]);

    return (
        <>
            {message && PLATFORM === PlatformType.Shopify &&
                <PolarisToast content={message} error={messageType === Toast_Message_Type.Error} duration={DURATION_MILLISECOND} onDismiss={onDismiss} />
            }
        </>
    );
}

export default Toast;