import React, { useCallback, useState } from 'react';
import { Input, Tag, TagLabel, TagCloseButton, Wrap, WrapItem } from '@chakra-ui/react';
import styles from './TagInput.module.css';

const TagInputWix = ({ onChange, onRemove, tags, placeholder }) => {
    const [value, setValue] = useState();

    const handleKeyPress = useCallback((event) => {
        if (event.key === 'Enter') {
            onChange(value);
            setValue('');
        }
    }, [value, onChange]);

    return (
        <Wrap className={styles.tag_input_wrap} align='center' >
            {tags.map((tag, index) => (
                <WrapItem key={index}>
                    <Tag >
                        <TagLabel>{tag}</TagLabel>
                        <TagCloseButton onClick={() => onRemove(tag)} />
                    </Tag>
                </WrapItem>
            ))}
            <WrapItem flexGrow={1}>
                <Input
                    className={styles.tag_input}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
            </WrapItem>
        </Wrap>
    );
};

export default TagInputWix;
