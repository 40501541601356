import React, { useMemo } from 'react'
import styles from './Subheader.module.css'
import { t } from 'i18next';
import { SHOPIFY_APP_ID } from '../../../config';

const Subheader = ({ label, helpText, systemSetting }) => {

    const helpTextUrl = useMemo(() => {
        // systemSetting.domain having value like store-name.myshopify.com so we are removing domain name here to get actual store domain name
        return helpText && systemSetting && t(helpText).replace('{STORE_NAME}', systemSetting.store_name?.replace('.myshopify.com', '') || '').replace('{APP_NAME}', SHOPIFY_APP_ID);
    }, [systemSetting, helpText]);

    return (
        <>
            <div className={styles.subheader_container} >
                {t(label)}
            </div>
            {helpText && <div className={styles.help_text} dangerouslySetInnerHTML={{ __html: helpTextUrl }} />}
        </>
    )
}

export default Subheader;