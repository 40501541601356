import React, { useState } from 'react';
import '../../../../../Style/index.css';
import styles from './TimerSection.module.css'
import RenderComponent from '../Shared/RenderComponent';
import { t } from 'i18next';

const TimerSection = (props) => {
    const [isOpen, setOpen] = useState(false);
    const { id, setting , widgetId, parentWidgetId, target, syncSettings, componentType } = props.data

    const handleChange = (e, value) => {
        e.stopPropagation();
        setOpen(value)
    }

    return (
        <>
            <div className={styles.custom_subheader} onClick={(e) => handleChange(e, !isOpen)}>
                <div className={isOpen ? styles.title : ''}>
                    {t(setting.label)}
                </div>
                <div className={isOpen ? styles.chevron_open : styles.chevron_close} onClick={(e) => handleChange(e, !isOpen)}>
                    <svg width="5" height="10" viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5 0L0 5L5 10V0Z" fill="#5C5F62" />
                    </svg>
                </div>
            </div>
            {isOpen && setting.settingGroups.map((subSetting, index) => {

                return (
                    <div key={index} className={styles.container}>
                        <RenderComponent data={{ setting: subSetting, id, widgetId, parentWidgetId, target, componentType: componentType, syncSettings: syncSettings }} />
                    </div>
                )
            })}

        </>
    )
}

export default TimerSection;
