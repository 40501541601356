//Application Types
export const QueryParams = {
    Token: 'token',
    VariantId: 'variant',
    Type: 'type',
    PlatformStoreId: 'platformStoreId',
    IsNewVariant: 'isNewVariant',
    RedirectUrl: 'redirectUrl',
    FromCondition: 'fromCondition',
    IsAdmin: 'isAdmin',
}

//Platform Types
export const PlatformType = {
    Wix: 'WIX',
    Shopify: 'SHOPIFY',
}

export const PreviewType = {
    CHECKOUT: 'CHECKOUT',
    POST_PURCHASE: 'POST_PURCHASE',
    CART_PAGE: 'CART_PAGE',
    ORDER_STATUS_PAGE: 'ORDER_STATUS_PAGE',
    PRODUCT_PAGE: 'PRODUCT_PAGE',
    THANK_YOU_PAGE: 'THANK_YOU_PAGE',
}

//Toast types
export const Toast_Message_Type = {
    Success: 'Success',
    Error: 'Error',
}

// Post message Types
export const eventsName = {
    HelpVideoShow: "help-video-show",
    HandleSave: "handle-save",
    HandleClose: 'handle-close',
    SaveForm: 'save-form',
    DiscardForm: 'discard-form',
};

//Conditional keys
export const ConditionalKeys = {
    MainProduct: 'MAIN_PRODUCT',
    BlockName: 'blockName',
    BannerLabel: 'Banner',
}

//Replace Target Place Holders
export const ReplaceTargetPlaceHolders = {
    SettingType: '{SETTING_TYPE}',
    Type: '{TYPE}',
    Id: '{ID}',
    WidgetId: '{WIDGET_ID}',
    TopWidgetId: '{TOP_WIDGET_ID}',
    Index: '{INDEX}',
}

//Targets
export const TargetKeys = {
    Setting: 'setting',
    WidgetId: `[id:${ReplaceTargetPlaceHolders.WidgetId}]`,
    TopWidgetId: `[id:${ReplaceTargetPlaceHolders.TopWidgetId}]`,
    Key: 'id',
    Widgets: 'widgets',
    Blocks: 'blocks',
    Block: 'block',
    Type: `[type:${ReplaceTargetPlaceHolders.Type}]`,
    Id: `[id:${ReplaceTargetPlaceHolders.Id}]`,
    SectionName: 'sectionName',
    Section: 'section',
    Max: 'max',
    Edit: 'Edit',
    Name: 'Name',
    Object: 'object',
    index: 'index',
}

//Target condition types
export const TargetConditionTypes = {
    Single: 'single',
    Multiple: 'multiple',
    MultiKey: 'multi-key',
    OuterTarget: 'outerTarget',
    EditorSchema: 'editorSchema',
    Parent: 'parent',
    SuperParent: 'superParent',
    ViewType: 'viewType'
}

// Logical Operators
export const LogicalOperators = {
    And: 'AND',
    Or: 'OR',
}

// Conditional Operators
export const ConditionalOperators = {
    Equal: 'equal',
    GreaterThan: 'gt',
    LessThan: 'lt',
    GreaterThanEqual: 'ge',
    LessThanEqual: 'le',
    NotEqual: 'ne',
    InvalidOperator: 'Invalid operator:',
}

//Widget Type
export const WidgetType = {
    MultiProductUpsell: 'MULTI_PRODUCT_UPSELL',
    Bundle: 'BUNDLE',
    Recommendations: 'RECOMMENDATIONS',
    Banner: 'BANNER',
    Divider: 'DIVIDER',
    Text: 'TEXT',
    Upsell: 'UPSELL',
    Timer: 'TIMER',
}

// parent widgets 
export const MultiProduct = [WidgetType.MultiProductUpsell, WidgetType.Bundle, WidgetType.Recommendations];

// Block type for UPSELL widget
export const UpsellBlocks = {
    Image: 'IMAGE',
    ProductDetails: 'PRODUCT_DETAILS',
    Description: 'DESCRIPTION',
    PriceAndDiscount: 'PRICE_AND_DISCOUNT',
    Button: 'BUTTON',
    VariantPicker: 'VARIANT_PICKER',
    QuantitySelector: 'QUANTITY_SELECTOR',
    SuccessErrorBanner: 'SUCCESS_ERROR_BANNER',
    Gallery: 'GALLERY',
    OrderSummary: 'ORDER_SUMMARY',
    AcceptButton: 'ACCEPT_BUTTON',
    DeclineButton: 'DECLINE_BUTTON',
    ProductTitle: 'PRODUCT_TITLE',
    TotalPrice: 'TOTAL_PRICE',
}

//Section type for Right Bar
export const ComponentType = {
    Dropdown: 'dropdown',
    MultiSelectDropDown: 'multiSelectDropDown',
    TagEditor: 'tagEditor',
    Checkbox: 'checkbox',
    Selector: 'selector',
    TextField: 'textField',
    TextEditor: 'textEditor',
    RadioButton: 'radioButtons',
    NumberField: 'numberField',
    Button: 'button',
    DescriptionText: 'descriptionText',
    Slider: 'slider',
    FieldGroup: 'fieldGroup',
    GroupContainer: 'groupContainer',
    Layout: 'layout',
    HelpText: 'helpText',
}

//Widget Category Type
export const WidgetCategoryType = {
	clickable : 'CLICKABLE',
	informational : 'INFORMATIONAL',
	converting : 'CONVERTING',
	container : 'CONTAINER'
}

//Widget Type Wise Category
export const WidgetTypeWiseCategory = {
    [WidgetType.Banner]: WidgetCategoryType.informational,
    [WidgetType.MultiProductUpsell]: WidgetCategoryType.container,
    [WidgetType.Bundle]: WidgetCategoryType.container,
    [WidgetType.Recommendations]: WidgetCategoryType.container,
    [WidgetType.Divider]: WidgetCategoryType.informational,
    [WidgetType.Text]: WidgetCategoryType.informational,
    [WidgetType.Upsell]: WidgetCategoryType.converting,
    [WidgetType.Timer]: WidgetCategoryType.informational,
}

//localStorage keys
export const LocalStorageKey = {
    AuthToken: 'authToken',
}

//Button event
export const ButtonEvent = {
    Back: 'Back'
}

// Block location 
export const BlockLocation = {
    LEFT: 1,
    RIGHT: 2,
    BOTTOM: 3,
}

export const Layouts = {
    ONE_COLUMN: "ONE_COLUMN",
    TWO_COLUMN: "TWO_COLUMN",
    THREE_COLUMN: "THREE_COLUMN",
    SMALL_LAYOUT_ONE: "SMALL_LAYOUT_ONE",
    SMALL_LAYOUT_TWO: "SMALL_LAYOUT_TWO",
    SMALL: "SMALL",
    MEDIUM: "MEDIUM",
    LARGE: "LARGE",
    LIST: "LIST",
    SLIDER: "SLIDER",
    GRID: "GRID",
    SHOWCASE: "SHOWCASE",
    SLIDER_DECK: "SLIDER_DECK",
}

// Default layout for widgets that contain upsell widget
export const DefaultLayoutForWidget = {
    [PreviewType.CART_PAGE]: {
        [WidgetType.MultiProductUpsell]: Layouts.SMALL,
        [WidgetType.Bundle]: Layouts.LIST,
        [WidgetType.Recommendations]: Layouts.GRID,
    },
    [PreviewType.CHECKOUT]: {
        [WidgetType.MultiProductUpsell]: Layouts.SMALL_LAYOUT_TWO,
        [WidgetType.Bundle]: Layouts.LIST,
        [WidgetType.Recommendations]: Layouts.SLIDER,
    },
    [PreviewType.ORDER_STATUS_PAGE]: {
        [WidgetType.MultiProductUpsell]: Layouts.SMALL_LAYOUT_TWO,
        [WidgetType.Bundle]: Layouts.LIST,
        [WidgetType.Recommendations]: Layouts.SLIDER,
    },
    [PreviewType.POST_PURCHASE]: {
        [WidgetType.MultiProductUpsell]: Layouts.SMALL_LAYOUT_ONE,
        [WidgetType.Bundle]: Layouts.LIST,
        [WidgetType.Recommendations]: Layouts.GRID,
    },
    [PreviewType.PRODUCT_PAGE]: {
        [WidgetType.MultiProductUpsell]: Layouts.SMALL,
        [WidgetType.Bundle]: Layouts.LIST,
        [WidgetType.Recommendations]: Layouts.SLIDER,
    },
    [PreviewType.THANK_YOU_PAGE]: {
        [WidgetType.MultiProductUpsell]: Layouts.SMALL_LAYOUT_TWO,
        [WidgetType.Bundle]: Layouts.LIST,
        [WidgetType.Recommendations]: Layouts.SLIDER,
    },
}

// Discount type for price and discount
export const DiscountType = {
    Percentage: "PERCENTAGE",
    FixedAmountPerUnit: "FIXED_AMOUNT_PER_UNIT",
    FixedAmountPerOrder: "FIXED_AMOUNT_PER_ORDER",
    IncrementalQuantityBreaks: "INCREMENTAL_QUANTITY_BREAKS"
}

// column layout for post purchase
export const ColumnLayout = [Layouts.ONE_COLUMN, Layouts.TWO_COLUMN, Layouts.THREE_COLUMN,Layouts.SMALL_LAYOUT_ONE,Layouts.SMALL_LAYOUT_TWO];

export const TwoSectionLayout = ['LEFT', 'RIGHT'];
export const ThreeSectionLayout = ['LEFT', 'RIGHT', 'BOTTOM'];
export const ThreeSectionLayoutVisible = {LEFT : 'isVisibleLeftColumnsBlocks', RIGHT: 'isVisibleRightColumnsBlocks', BOTTOM: 'isVisibleBottomColumnsBlocks'};
const setting = {
    isDraggable: false,
    condition: "isVisible",
    isVisible: true,
    sectionSettings: [],
    maxCount: 1,
    count: 0,
    minCount: 1,
}

export const ThreeSectionLayoutSettings = {
    LEFT: {
        sectionName: "left_column",
        type: "LEFT",
        icon: "left.svg",
        target: "widgets.[id:{TOP_WIDGET_ID}].upsellInfo.isVisibleLeftColumnsBlocks",
        ...setting,
    },
    RIGHT: {
        sectionName: "right_column",
        type: "RIGHT",
        icon: 'right.svg',
        target: "widgets.[id:{TOP_WIDGET_ID}].upsellInfo.isVisibleRightColumnsBlocks",
        ...setting,
    },
    BOTTOM: {
        sectionName: "bottom",
        type: "BOTTOM",
        icon: 'bottom.svg',
        target: "widgets.[id:{TOP_WIDGET_ID}].upsellInfo.isVisibleBottomColumnsBlocks",
        ...setting,
    }
};

export const ProductDisplayTypes = ['SHOPIFY_RECOMMENDATIONS', 'MOST_EXPENSIVE', 'LEAST_EXPENSIVE', 'SPECIFIC_PRODUCT', 'WISER_RECOMMENDATIONS', 'PERSONALIZED_RECOMMENDATIONS', 'RECOMATIC_RECOMMENDATIONS'];

export const ThirdPartyApps = {
    WISER_RECOMMENDATIONS: {
        text: 'wise_recommendations_banner_text',
        description: 'wise_recommendations_description',
        modalTitle: 'Wiser',
        modalLogo: 'wiser.svg',
        showModalTitle: false,
        productType: 'WISER'
    },
    RECOMATIC_RECOMMENDATIONS: {
        text: 'reconvert_recommendations_banner_text',
        description: 'reconvert_recommendations_description',
        modalTitle: 'Recomatic',
        modalLogo: 'recomatic.svg',
        showModalTitle: false,
        productType: 'RECOMATIC'
    },
    PERSONALIZED_RECOMMENDATIONS: {
        text: 'personalized_recommendations_banner_text',
        description: 'personalized_recommendations_description',
        modalTitle: 'Personalized recommendations',
        modalLogo: 'glood.svg',
        showModalTitle: true,
        productType: 'PERSONALIZED'
    }
}

export const ViewType = {
    mobile: "mobile",
    desktop: "desktop"
}