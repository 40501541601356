import { Checkbox as ChakraCheckbox } from '@chakra-ui/react';
import { Checkbox as PolarisCheckbox } from '@shopify/polaris';

import styles from './Checkbox.module.css'
import Tooltip from '../Tooltip';
import { PLATFORM } from '../../../config';
import { PlatformType } from '../../../Constant';
import { t } from 'i18next';

const CheckBox = ({ label, checked, onChange, showTooltip, tooltipText, disabled, helpText }) => {
    return (
        <div className={styles.checkbox_wrap}>
            <div className={styles.checkbox_field}>
                {PLATFORM === PlatformType.Wix ?
                    <ChakraCheckbox className={styles.chakra_checkbox} checked={checked} readOnly={disabled} isChecked={checked} onChange={(e) => { if (!disabled) onChange(e.target.checked) }}>{t(label)}</ChakraCheckbox>
                    :
                    <PolarisCheckbox
                        label={t(label)}
                        checked={checked}
                        onChange={(e) => { if (!disabled) onChange(e) }}
                    />
                }
                {showTooltip && <Tooltip tooltipText={t(tooltipText)} />}
            </div>
            {helpText && <div className={styles.help_text} dangerouslySetInnerHTML={{ __html: t(helpText) }} />}
        </div>
    );
}

export default CheckBox;