import { Alert } from '@chakra-ui/react'
import { Banner as PolarisBanner } from '@shopify/polaris'
import React from 'react'
import { PLATFORM } from '../../../config'
import { PlatformType } from '../../../Constant'

export const BannerType = {
    Error: 'critical',
    Success: 'success',
    Info: 'info',
    Warning: 'warning'
}

const BannerTypeMap = {
    [BannerType.Error]: {
        [PlatformType.Wix]: 'error',
        [PlatformType.Shopify]: 'critical'
    },
    [BannerType.Success]: {
        [PlatformType.Wix]: 'success',
        [PlatformType.Shopify]: 'success'
    },
    [BannerType.Info]: {
        [PlatformType.Wix]: 'info',
        [PlatformType.Shopify]: 'info'
    },
    [BannerType.Warning]: {
        [PlatformType.Wix]: 'warning',
        [PlatformType.Shopify]: 'warning'
    }
}

const Banner = ({ status, hideIcon, onDismiss, children}) => {
    const bannerType = BannerTypeMap[status]?.[PLATFORM];
    return (
        <>
            {PLATFORM === PlatformType.Wix ?
                <Alert status={bannerType}>
                    {children}
                </Alert>
                :
                <PolarisBanner status={bannerType} hideIcon={hideIcon} onDismiss={onDismiss}>
                    {children}
                </PolarisBanner>
            }
        </>
    )
}

export default Banner