import React from 'react'
import { Icon as PolarisIcon } from '@shopify/polaris'
import { CaretDownMinor, CirclePlusMinor, CircleTickMajor, DeleteMinor, DragHandleMinor, ExitMajor, HideMinor, PlayMinor, RedoMajor, UndoMajor, ViewMinor, DesktopMajor, MobileMajor } from '@shopify/polaris-icons'
import { CDN_BASE_URL, PLATFORM } from '../../../config'
import { PlatformType } from '../../../Constant'

export const IconSource = {
  Exit: 'Exit',
  Delete: 'Delete',
  Tick: 'Tick',
  Play: 'Play',
  Undo: 'Undo',
  Redo: 'Redo',
  CirclePlus: 'CirclePlus',
  Plus: 'Plus',
  Caret: 'Caret',
  DragHandle: 'DragHandle',
  EyeOpen: 'EyeOpen',
  EyeClose: 'EyeClose',
  Desktop: 'Desktop',
  Mobile: 'Mobile',
}

export const IconSourceMap = {
  [IconSource.Exit]: {
    [PlatformType.Wix]: 'exit_button.svg',
    [PlatformType.Shopify]: ExitMajor
  },
  [IconSource.Delete]: {
    [PlatformType.Wix]: 'remove.svg',
    [PlatformType.Shopify]: DeleteMinor
  },
  [IconSource.Tick]: {
    [PlatformType.Wix]: 'tick.svg',
    [PlatformType.Shopify]: CircleTickMajor
  },
  [IconSource.Play]: {
    [PlatformType.Wix]: 'play.svg',
    [PlatformType.Shopify]: PlayMinor
  },
  [IconSource.Undo]: {
    [PlatformType.Wix]: 'undo.svg',
    [PlatformType.Shopify]: UndoMajor
  },
  [IconSource.Redo]: {
    [PlatformType.Wix]: 'redo.svg',
    [PlatformType.Shopify]: RedoMajor
  },
  [IconSource.CirclePlus]: {
    [PlatformType.Wix]: 'plus_rounded.svg',
    [PlatformType.Shopify]: CirclePlusMinor
  },
  [IconSource.Plus]: {
    [PlatformType.Wix]: 'plus_icon.svg',
    [PlatformType.Shopify]: CirclePlusMinor
  },
  [IconSource.Caret]: {
    [PlatformType.Wix]: 'caret.svg',
    [PlatformType.Shopify]: CaretDownMinor
  },
  [IconSource.DragHandle]: {
    [PlatformType.Wix]: 'drag_handle.svg',
    [PlatformType.Shopify]: DragHandleMinor
  },
  [IconSource.EyeOpen]: {
    [PlatformType.Wix]: 'viewMinor.svg',
    [PlatformType.Shopify]: ViewMinor
  },
  [IconSource.EyeClose]: {
    [PlatformType.Wix]: 'hideMinor.svg',
    [PlatformType.Shopify]: HideMinor
  },
  [IconSource.Desktop]: {
    [PlatformType.Wix]: 'desktop.svg',
    [PlatformType.Shopify]: DesktopMajor
  },
  [IconSource.Mobile]: {
    [PlatformType.Wix]: 'mobile.svg',
    [PlatformType.Shopify]: MobileMajor
  },
}

const Icon = ({ source, iconName, iconAlt, className, children }) => {
  const sourceIcon = IconSourceMap[source]?.[PLATFORM];

  return (
    <>
      { PLATFORM === PlatformType.Wix ?
          children ? 
            children :
            source ? 
            <img src={`${CDN_BASE_URL}assets/wix/images/${sourceIcon}`} alt={`${iconAlt} icon`} className={className ? className : ''} /> : 
            <img className={className ? className : ''} src={`${CDN_BASE_URL}assets/wix/images/${iconName}`} alt={`${iconAlt} icon`} />
          :
          source ? <PolarisIcon source={sourceIcon} /> : <img className={className ? className : ''} src={`${CDN_BASE_URL}assets/shopify/images/${iconName}`} alt={`${iconAlt} icon`} />
      }
    </>
  )
}

export default Icon;