import React, { useCallback } from 'react'
import TagInputShopify from './TagInputShopify'
import TagInputWix from './TagInputWix'
import Label from '../Label'
import { t } from 'i18next'
import { PLATFORM } from '../../../config'
import { PlatformType } from '../../../Constant'

const TagInput = (props) => {
    const { label, tooltipText, showTooltip, onChange } = props;

    const handleRemoveTag = useCallback((tag) => {
        onChange(tag);
    }, [onChange]);

    return (
        <>
            {label && <Label label={t(label)} tooltipText={t(tooltipText)} showTooltip={showTooltip} />}
            {PLATFORM === PlatformType.Wix ?
                <TagInputWix {...props} onRemove={handleRemoveTag} />
                :
                <TagInputShopify {...props} onRemove={handleRemoveTag} />
            }
        </>
    )
}

export default TagInput;