import { LegacyStack, RadioButton } from '@shopify/polaris';
import { useState, useEffect, useCallback } from 'react';
import Label from '../Label';
import { t } from 'i18next';

const RadioButtonGroup = ({ isShowTooltip, label, options, onChange, value, tooltipContent }) => {
    const [selectedValue, setSelectedValue] = useState(value);

    const handleChange = useCallback((_checked, newValue) => {
        setSelectedValue(newValue);
        onChange(newValue);
    }, [onChange]);

    useEffect(() => {
        setSelectedValue(value)
    }, [value])

    return (
        <div>
            <Label label={label} tooltipText={tooltipContent} showTooltip={isShowTooltip} />
            <LegacyStack vertical spacing='tight'>
                {options?.map((item) => {
                    return (
                        <RadioButton
                            key={item.value}
                            label={t(item.label)}
                            checked={selectedValue === item.value}
                            id={item.value}
                            name={item.label}
                            onChange={handleChange}
                        />
                    )
                })}
            </LegacyStack>
        </div>
    );
}

export default RadioButtonGroup