const ComponentStyles = ({active, isFrameLoad}) => {
    return (
        <>
            {active &&
                <style jsx='false' global='false'>
                    {`

                        .Polaris-Modal-CloseButton{
                            display:none
                       }

                       .model-main .modal-dialog {                        
                           z-index: 0!important;
                       }

                       .modal-backdrop{ 
                           z-index: 0!important;
                       }
                       
                       .Polaris-DisplayText{
                          text-align:center
                       }
     
                       @media screen and (min-width: 48.0625em) {
                         .Polaris-Modal-Dialog__Modal {
                             max-width: 900px;
                             height: calc(100vh - 86px);
                             max-height:100%
                         }

                         .chakra-modal__content-container > section {
                            max-width: none;
                            width: 50%;
                            height: 80%;
                         }
                       }
    
                       .Polaris-Modal__IFrame{
                          max-width:100% ;
                          width: 100%;
                          height:100%
                       }

                       .Polaris-Modal__BodyWrapper > iframe{
                          height:100% !important
                       }

                       .Polaris-Modal__IFrame{
                        height: 100% !important
                       }

                       @media screen and (max-width: 48.0625em) {
                        .chakra-modal__content-container > section {
                            max-width: none;
                            width: 100%;
                            height: 90%;
                         }
                      }
             
                     `}
                </style>
            }

            {!isFrameLoad && active &&
                <style jsx='false' global='false'>
                    {`
               
                       @media screen and (min-width: 48.0625em) {
                         .Polaris-Modal-Dialog__Modal {
                            height: calc(100vh - 86px);
                         }
                       }
             
                    `}
                </style>
            }
        </>
    )
}
export default ComponentStyles