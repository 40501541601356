import React from 'react';
import styles from './HelpText.module.css';
import { t } from 'i18next';

const HelpText = ({ helpText }) => {
    return (
        <>{helpText && <div className={styles.help_text} dangerouslySetInnerHTML={{ __html: t(helpText) }} />}</>
    )
}

export default HelpText;