import { useCallback, useContext, useEffect, useState } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import Froalaeditor from 'froala-editor';
import styles from './TextEditor.module.css';
import Label from '../Label';
import { t } from 'i18next';
import { FROALA_EDITOR_KEY } from '../../../config';
import { PageData } from '../../../ContextAPI/Context';
import { PreviewType } from '../../../Constant';

const post_purchase_options = {
    '12px': 'Small',
    '16px': 'Medium',
    '18px': 'Large',
    '24px': 'Extra Large'
}

const checkout_options = {
    '12px': 'Small',
    '16px': 'Medium',
    '19px': 'Large',
    '21px': 'Extra Large'
}

const TextEditor = (props) => {
    const { previewType } = useContext(PageData);
    const { label, tooltipText, defaultText, onChange, showTooltip, value } = props;
    const [editorValue, setEditorValue] = useState("");
    const [initComplete, setInitComplete] = useState(false);  

    const handleChange = useCallback((e) => {
        setEditorValue(e);
        // The initial rendering of the FroalaEditor is update the state, so preventing that initial change event.
       initComplete ? onChange(e) : setInitComplete(true);
         
    }, [onChange, initComplete]);

    useEffect(() => {
        if(editorValue !== value)  {
            setInitComplete(false);
            setEditorValue(value);
        }
         
    }, [value]);

    Froalaeditor.DefineIcon('textSize', { NAME: 'fontSize', SVG_KEY: 'fontSize' });
    Froalaeditor.RegisterCommand('textSizeCustom', {
        title: 'Font Size',
        icon: 'textSize',
        type: 'dropdown',
        options: previewType === PreviewType.POST_PURCHASE ? post_purchase_options : checkout_options,
        focus: true,
        undo: true,
        refreshAfterCallback: true,
        callback: function (cmd, val) {
            switch (val) {
                // If want to update font size then also need to update in index.css for preview
                case 'h1':
                    this.fontSize.apply('28px');
                    break;
                case 'h2':
                    this.fontSize.apply('18px');
                    break;
                case 'h3':
                    this.fontSize.apply('14px');
                    break;
                default:
                    this.fontSize.apply(val);
                    break;
            }
        }
    })

    // Define a text icon called imageIcon.
    // Froalaeditor.DefineIcon('italic', { SRC: '../../assets/italicicon.png', ALT: 'italic icon', template: 'image' });

    const toolbarOptions = ['insertLink', 'textSizeCustom', 'align', 'textAlign', 'bold'];
    return (
        <>
            <div className={`${styles.editor_wrap}  ${''}`}>
                {label && <Label label={t(label)} tooltipText={tooltipText} showTooltip={showTooltip} /> }
                <div className={`${styles.editor_field} froala-editor`}>
                    <FroalaEditor
                        id='froala-editor-custom'
                        tag='textarea'
                        model={editorValue?.toString() || ''}
                        config={{                            
                            key: FROALA_EDITOR_KEY,
                            toolbarButtons: toolbarOptions,
                            placeholderText: defaultText || '',
                            charCounterCount: false,
                            fontSize: ['small', '10', '12', '14', '18', '30', '60', '96'],
                            linkAlwaysBlank: true,
                            linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
                            quickInsertEnabled: false,
                            pastePlain: true,
                            imagePaste: false,
                            toolbarSticky: false
                        }}
                        onModelChange={handleChange}
                    />
                </div>
            </div>
        </>
    )
}

TextEditor.defaultProps = [
    {
        label: 'Title',
        tooltipText: 'Title',
        defaultText: 'Testing',
        editorValue: 'Testing',
    }
];

export default TextEditor;