import React from 'react'
import { Text as PolarisText } from '@shopify/polaris';
import { Text as ChakraText } from '@chakra-ui/react'
import { PLATFORM } from '../../../config';
import { PlatformType } from '../../../Constant';

const Text = ({ children }) => {
    return (
        <>
            {PLATFORM === PlatformType.Wix ?
                <ChakraText fontSize='4xl' as='p'>{children}</ChakraText>
                :
                <PolarisText variant='heading2xl' as='p'>{children}</PolarisText>
            }
        </>
    )
}

export default Text