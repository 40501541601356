import { PlatformType } from '../../../../../../Constant';
import { fetchProduct } from '../../../../../../Service';
import { CDN_BASE_URL } from '../../../../../../config';

export const getProductResponse = async (productId, platform) => {
  const productRes = await fetchProduct(productId);
  if (platform === PlatformType.Shopify) {
    return productRes.data.product;
  } else {
    const wixProduct = productRes.data.product;
    const images = getImages(wixProduct);
    const variants = getVariants(wixProduct, images);
    const convertedResponse = {
      id: wixProduct.id,
      title: wixProduct.name,
      body_html: wixProduct.description,
      vendor: wixProduct?.brand || '',
      product_type: wixProduct.productType,
      handle: wixProduct.slug,
      status: wixProduct.visible ? "active" : "inactive",
      variants: variants,
      options: wixProduct.productOptions.length > 0 ? wixProduct.productOptions.map((option, index) => ({
        product_id: wixProduct.id,
        name: option?.name,
        position: 1,
        values: wixProduct.productOptions[index].choices.map((choice) => choice.description)
      })) : [],
      images: images,
      image: {
        product_id: wixProduct.id,
        src: wixProduct?.media?.mainMedia?.image?.url || CDN_BASE_URL + 'assets/dummy_small.png',
      },
      ribbon: wixProduct.ribbon,
    };

    return convertedResponse;
  }
}

// check image relatd to choice in product variant
const getImageId = (images, choices) => {
  if (choices) {
    for (const choice of choices) {
      const selectedImage = images.find((image) => image.choice === choice);
      if (selectedImage && selectedImage.src) {
        return selectedImage.id;
      }
    }
  }
  return null;
}

const getVariants = (wixProduct, images) => {
  let variants = [];
  if (wixProduct?.variants?.length > 1) {
    // If variant is more than 1, we need to get all the variants
    variants = wixProduct.variants.map((variant) => ({
      id: variant.id,
      product_id: wixProduct.id,
      title: Object.values(variant.choices).join(" / "),
      price: variant.variant.priceData.discountedPrice, // According to old Wix preview "CompareToPrice" as "Price" and "OrigonalPrice" as "discountedPrice".
      compare_at_price:  variant.variant.priceData.price,
      image_id: getImageId(images, Object.values(variant.choices)),
    }));
  } else if (wixProduct.productOptions.length > 0) {
    // If variant is one, we need to get all the choices
    const options = generateProductOptions(wixProduct.productOptions)

    variants = options.map((choice) => ({
      id: generateRandomNumber(),
      product_id: wixProduct.id,
      title: Object.values(choice).join(" / "),
      price: `${wixProduct.variants[0].variant.priceData.discountedPrice}`, // According to old Wix preview "CompareToPrice" as "Price" and "OrigonalPrice" as "discountedPrice". 
      compare_at_price: `${wixProduct.variants[0].variant.priceData.price}`, // Need to get the price and discountedPrice from the first variant because of variant is only one
      image_id: getImageId(images, Object.values(choice)),
    }));
  } else {
    variants = [{
      id: wixProduct.variants[0].id,
      product_id: wixProduct.id,
      title: wixProduct.variants[0].variant.choices || '',
      price: `${wixProduct.variants[0].variant.priceData.discountedPrice}`, // According to old Wix preview "CompareToPrice" as "Price" and "OrigonalPrice" as "discountedPrice". 
      compare_at_price: `${wixProduct.variants[0].variant.priceData.price}`, // Need to get the price and discountedPrice from the first variant because of variant is only one
      image_id: images.length > 0 ? images[0].id : null,
    }];
  }
  return variants;
}

const generateProductOptions = (productOptions) => {
  const result = [];
  function cartesianProduct(currentIndex, partialResult) {
    if (currentIndex === productOptions.length) {
      result.push(partialResult);
      return;
    }
    const currentOption = productOptions[currentIndex];
    for (let i = 0; i < currentOption.choices.length; i++) {
      const choice = currentOption.choices[i];
      const newPartialResult = { ...partialResult, [currentOption.name]: choice.description };
      cartesianProduct(currentIndex + 1, newPartialResult);
    }
  }
  cartesianProduct(0, {});
  return result;
}

const getImages = (wixProduct) => {
  let images = [];
  if (wixProduct.productOptions.length > 0) {
    wixProduct.productOptions.map((option) => {
      const imageArr = option.choices.map((choice) => ({
        id: generateRandomNumber(),
        product_id: wixProduct.id,
        choice: choice.description,
        src: choice?.media?.mainMedia?.image?.url,
      }));
      images.push(...imageArr);
    })
  }
  // If no images, we need to get all the images from media.items
  if (images.length === 0) {
    if(wixProduct.media?.items?.length > 0){
      images = wixProduct.media.items.map((media) => ({
        id: media.id,
        product_id: wixProduct.id,
        src: media.image?.url || wixProduct.media.mainMedia?.image?.url || media.thumbnail?.url || CDN_BASE_URL + 'assets/dummy_small.png'
      }));
    } else { // if media.items is empty, we need to set dummy images
      images = [{
        id: generateRandomNumber(),
        product_id: wixProduct.id,
        src: CDN_BASE_URL + 'assets/dummy_small.png'
      }];
    }
  }
  return images;
}

function generateRandomNumber() {
  const min = Math.pow(10, 11);
  const max = Math.pow(10, 12) - 1;

  return `${Math.floor(Math.random() * (max - min + 1)) + min}`;
}