import React, { useState, useCallback, useRef, useContext } from 'react';
import { t } from 'i18next';
import { PageData } from '../../../../ContextAPI/Context';
import { Icon, Modal } from '../../../../Shared/Components';
import { IconSource } from '../../../../Shared/Components/Icon';
import styles from './ExitButton.module.css';
import { QueryParams } from '../../../../Constant';
import { isOpenedFromFlowBuilder } from '../../../../Utils/Common';

const ExitButton = () => {
  const [active, setActive] = useState(false);
  const { configSetting } = useContext(PageData);
  const activator = useRef(null);

  const handleExit = useCallback(() => {
    setActive(false);
    // If the editor opens from the flow builder, the exit button will return a postMessage; otherwise, it will redirect using the redirect URL.
    if (isOpenedFromFlowBuilder()) {
      if (window.parent) {
        window.parent.postMessage({
          eventType: "exit",
          data: true
        }, "*");
      }
    } else {
      const params = window.location.search;
      const redirectUrl = new URLSearchParams(params).get(QueryParams.RedirectUrl);
      redirectUrl && (document.location.href = redirectUrl);
    }
  }, []);

  const handleOpen = useCallback(() => configSetting.length >= 2 ? setActive(true) : handleExit(), [configSetting, handleExit]);

  const handleClose = useCallback(() => {
    setActive(false);
  }, []);

  return (
    <>
      <div className={styles.exit_button} ref={activator} onClick={handleOpen}>
        <Icon source={IconSource.Exit} iconAlt={'exit_button'} >
          <svg width="30" height="20" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.75 0C4.50736 0 3.5 1.00736 3.5 2.25C3.5 2.66421 3.83579 3 4.25 3C4.66421 3 5 2.66421 5 2.25C5 1.83579 5.33579 1.5 5.75 1.5H9.75C10.1642 1.5 10.5 1.83579 10.5 2.25V11.75C10.5 12.1642 10.1642 12.5 9.75 12.5H5.75C5.33579 12.5 5 12.1642 5 11.75C5 11.3358 4.66421 11 4.25 11C3.83579 11 3.5 11.3358 3.5 11.75C3.5 12.9926 4.50736 14 5.75 14H9.75C10.9926 14 12 12.9926 12 11.75V2.25C12 1.00736 10.9926 0 9.75 0H5.75Z" fill="#4A4A4A" />
            <path d="M8.25 7.75C8.66421 7.75 9 7.41421 9 7C9 6.58579 8.66421 6.25 8.25 6.25L2.56066 6.25L3.53033 5.28033C3.82322 4.98744 3.82322 4.51256 3.53033 4.21967C3.23744 3.92678 2.76256 3.92678 2.46967 4.21967L0.21967 6.46967C0.0790174 6.61032 0 6.80109 0 7C0 7.19891 0.0790174 7.38968 0.21967 7.53033L2.46967 9.78033C2.76256 10.0732 3.23744 10.0732 3.53033 9.78033C3.82322 9.48744 3.82322 9.01256 3.53033 8.71967L2.56066 7.75H8.25Z" fill="#4A4A4A" />
          </svg>
        </Icon>
        <span className={styles.exit_span}>Back</span>
      </div>
      <Modal
        rootStyles={{ height: '500px' }}
        active={active}
        onSecondaryAction={handleClose}
        onPrimaryAction={handleExit}
        title={t('exit_button_title')}
        activator={activator}
        isPrimaryButtonDanger
        primaryButtonLabel={t('exit_without_saving')}
        secondaryButtonLabel={t('cancel')}
        content={t('exit_button_content')}
      />
    </>
  );
};

export default ExitButton;
