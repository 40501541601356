import React, { useMemo, useState } from 'react';
import { Listbox, Popover, Scrollable, EmptySearchResult, Button } from '@shopify/polaris';
import Icon from '../Icon';
import './PopoverShopify.css'; // Shopify polaris some component not support className properties to add css from module.css there for add css from popoverShopify.css 
import { t } from 'i18next';

const PopoverShopify = (props) => {
    const { iconSource, title, options, onClick, titleClassName } = props;
    const [active, setActive] = useState(false);

    const handleOpenPicker = () => {
        setActive(!active);
    };

    const handleClosePicker = () => {
        setActive(false);
    };

    const handleSegmentSelect = (segmentIndex) => {
        onClick(segmentIndex);
        handleClosePicker();
    };

    const optionList = useMemo(() => {
        if (options.length > 0) {
            return (
                options.map(({ label, icon, value }) => {
                    return (
                        <Listbox.Option key={value} value={value} selected={false}>
                            <Listbox.TextOption selected={false}>
                                <Icon iconName={icon} iconAlt={icon} className={'list-icon'} />
                                {t(label)}
                            </Listbox.TextOption>
                        </Listbox.Option>
                    );
                })
            )
        }
        return null;
    }, [options]);

    const noResultsMarkup = useMemo(() => {
        if (options.length === 0) {
            return (
                <EmptySearchResult
                    title=''
                    description={t('no_results_found')}
                />
            )
        }
        return null;
    }, [options]);

    const activator = useMemo(() => {
        return (
            <div className='shopify-popover'>
                <div className={`activator-container ${titleClassName && titleClassName}`}>
                    <Button onClick={handleOpenPicker} icon={<Icon source={iconSource} />} >
                        {title}
                    </Button>
                </div>
            </div>
        )
    }, [title, titleClassName, iconSource]);


    return (
        <div style={{ height: '38px' }}>

            <Popover
                active={active}
                activator={activator}
                ariaHaspopup='listbox'
                preferredAlignment='left'
                onClose={handleClosePicker}
            >
                <Popover.Pane fixed>
                    <div className='popover_list_container' >
                        <Scrollable
                            shadow
                            className='popover_scrollable'
                        >
                            <div className='popover_title'>
                                {title.toLocaleUpperCase()}
                            </div>
                            <Listbox
                                enableKeyboardControl
                                accessibilityLabel='select a widget option'
                                onSelect={handleSegmentSelect}
                                autoSelection={'NONE'}
                            >
                                {optionList}
                                {noResultsMarkup}
                            </Listbox>
                        </Scrollable>
                    </div>
                </Popover.Pane>
            </Popover>
        </div >
    );
}

export default PopoverShopify;