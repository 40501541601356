import { Spinner as ChakraSpinner } from '@chakra-ui/react'
import { Spinner as PolarisSpinner } from '@shopify/polaris'
import React from 'react'
import { PLATFORM } from '../../../config'
import { PlatformType } from '../../../Constant'

const SpinnerSizeList = {
    large: {
        [PlatformType.Wix]: 'xl',
        [PlatformType.Shopify]: 'large'
    },
    small: {
        [PlatformType.Wix]: 'sm',
        [PlatformType.Shopify]: 'small'
    }
}

const Spinner = ({ color, size, label, thickness }) => {
    const spinnerSize = SpinnerSizeList[size]?.[PLATFORM];
    return (
        <>
            {PLATFORM === PlatformType.Wix ?
                <ChakraSpinner color={color} size={spinnerSize} thickness={thickness}/> :
                <PolarisSpinner size={spinnerSize} accessibilityLabel={label} />
            }
        </>
    )
}

export default Spinner