import React from 'react'
import PopoverWix from './PopoverWix'
import PopoverShopify from './PopoverShopify'
import { PLATFORM } from '../../../config'
import { PlatformType } from '../../../Constant'

const Popover = (props) => {
    
    return (
        <>
            {PLATFORM === PlatformType.Wix ?
                <PopoverWix {...props}/> 
                :
                <PopoverShopify {...props}/>
            }
        </>
    )
}

export default Popover