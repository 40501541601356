import { Button } from '@shopify/polaris';
import { useState, useEffect } from 'react';
import { CDN_BASE_URL } from '../../../config';
import Label from '../Label';
import { t } from 'i18next';
import { Tooltip } from '../../../Shared/Components';
import * as _ from 'lodash';

const LayoutSelector = ({ showTooltip, label, onChange, value, tooltipText, layouts }) => {
    const [selectedValue, setSelectedValue] = useState(value);

    const handleChange = (newValue) => {
        setSelectedValue(newValue)
        onChange(newValue, value)
    }

    useEffect(() => {
        setSelectedValue(value)
    }, [value])

    return (
        <div className='layout-selector-container'>
            {label && <Label label={t(label)} tooltipText={tooltipText} showTooltip={showTooltip} />}
            <div className='layout-selector-buttons-container'>
                {layouts?.map((item) => {
                    return (
                        <Tooltip key={item.value} tooltipText={_.capitalize(item.value).replace(/_/g, " ")}>
                            <Button disabled={selectedValue === item.value}  onClick={() => handleChange(item.value)}>
                                <img src={CDN_BASE_URL + `assets/shopify/images/${item.icon}`} alt={`layout ${item.value}`} />
                            </Button>
                        </Tooltip>
                        )
                    }
                )}
            </div>
        </div>
    );
}

export default LayoutSelector;