import { Icon } from '@shopify/polaris';
import { CloseButton as CloseIconButton } from '@chakra-ui/react'
import { CancelSmallMinor } from '@shopify/polaris-icons';

import styles from './CloseButton.module.css';
import { PLATFORM } from '../../../config';
import { PlatformType } from '../../../Constant';


const CloseButton = ({ onClick }) => {
  return (
    <>
      {PLATFORM === PlatformType.Wix ?
         <CloseIconButton size='md' onClick={onClick}/>
        :
        <span className={styles.close_button} onClick={onClick}>
          <Icon source={CancelSmallMinor} color='base' />
        </span>
      }
    </>
  );
};

export default CloseButton;
