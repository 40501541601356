import React from 'react';
import { DropZone } from '@shopify/polaris';
import styles from './ImageUpload.module.css';
import ChakraImageUpload from './ChakraImageUpload';
import { t } from 'i18next';
import { PLATFORM } from '../../../config';
import { PlatformType } from '../../../Constant';

export const ACCEPT_FILE_TYPE = ['image/gif', 'image/jpg', 'image/png', 'image/svg+xml', 'image/jpeg'];
const ImageUpload = ({ onUpload, disabled, multiple }) => {

    return (
        <>
            {PLATFORM === PlatformType.Wix ?
                <ChakraImageUpload onUpload={onUpload} disabled={disabled} multiple={multiple} />
                :
                <DropZone accept={ACCEPT_FILE_TYPE} type='image' onDrop={onUpload} disabled={disabled} allowMultiple={multiple}>
                    <div className={styles.drop_upload_image_box}>
                        <p>{t('upload')}</p>
                    </div>
                </DropZone>
            }
        </>
    )
}

export default ImageUpload;