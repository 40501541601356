import React, { useState, useEffect, useContext, useMemo } from 'react';
import { cloneDeep } from 'lodash';
import { t } from 'i18next';

import { PageData } from '../../../../../ContextAPI/Context';
import { getTargetedValue, getSelectedWidget, checkTargetConditions } from '../../../../../Utils/Common';
import { Header, Button, Subheader } from '../../../../../Shared/Components';
import RenderComponent from '../Shared/RenderComponent';
import { ComponentType } from '../../../../../Constant';
import styles from './SuccessErrorBanner.module.css';

const SuccessErrorBanner = props => {
  const { configSetting, unifiedEditor, bannerStatus, setBannerStatus, isMobileView } = useContext(PageData);
  const [title, setTitle] = useState('');
  const [widgetSettings, setWidgetSettings] = useState([props]);
  const [componentId, setComponentId] = useState();
  const { id, widgetId, parentWidgetId, target, blockType } = props.type;

  const latestSettings = useMemo(() => {
    return cloneDeep(configSetting[configSetting.length - 1]);
  }, [configSetting]);

  useEffect(() => {
    const targetedWidget = getTargetedValue(latestSettings, target, widgetId, parentWidgetId, blockType, id);
    const selectedWidget = getSelectedWidget(targetedWidget, unifiedEditor.widgets);
    setComponentId(targetedWidget?.id);
    setWidgetSettings(selectedWidget);
    setTitle(selectedWidget?.blockName);
  }, [latestSettings, unifiedEditor, target, id, widgetId, parentWidgetId, blockType]);

  const handleClick = (type) => {
    const bannerSetting = cloneDeep(bannerStatus);
    bannerSetting[componentId] = type;

    if (bannerStatus?.[componentId] === type) {
      setBannerStatus({});
    } else {
      setBannerStatus(bannerSetting);
    }
  }

  try {
    return (
      <div className='rightbar-content'>
        {widgetSettings.count > 0 ? (
          <>
            <Header label={title} showHelpLink/>
            {widgetSettings.blockSettings.map((setting, index) => {
              return (
                <React.Fragment key={index}>
                  <Subheader label={setting.label} />
                  {setting.settingGroups.map((subSetting, index) => {
                    if (subSetting.conditional && !checkTargetConditions({widgets: latestSettings, target: `${target}.${subSetting.target}`, conditions: subSetting.condition, widgetId: widgetId, parentWidgetId: parentWidgetId, _type: blockType, id: id, isMobileView})) {
                      return null;
                    } else if (subSetting.type === ComponentType.Button) {
                      return (
                        <div key={index} className={styles.preview_button}>
                          <Button onClick={() => handleClick(subSetting.status)} color='teal' variant='outline'>{bannerStatus?.[componentId] === subSetting.status ? t('preview_the_upsell_section') : t(subSetting.label)} </Button>
                        </div>
                      )
                    } else {
                      return (
                        <React.Fragment key={index}>
                          <RenderComponent data={{ setting: subSetting, id, widgetId, parentWidgetId, target, componentType: blockType, syncSettings: widgetSettings?.syncSettings }} />
                        </React.Fragment>
                      );
                    }
                  })}
                  {widgetSettings?.blockSettings?.length - 1 !== index && <div className='content-divider' />}
                </React.Fragment>
              );
            })}
          </>
        ) : null}
      </div>
    );
  } catch (err) {
    return (
      <>
        <div className='m-5 text-center text-secondary'>Something went wrong..</div>
      </>
    )
  }
};

export default SuccessErrorBanner;
