import ModalView from './ModalView';

const Modal = (props) => {
    const { rootStyles } = props;

    return (
        <div style={{ ...rootStyles }}>
            <ModalView {...props} />
        </div>
    );
}

export default Modal;