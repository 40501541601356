import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import '../../Style/index.css';
import { PageData } from '../../ContextAPI/Context';
import Header from './Header';
import LeftSideBar from './LeftSideBar';
import RightSideBar from './RightSideBar';
import HelpModal from './HelpModal/index';
import { ButtonEvent, PlatformType } from '../../Constant';
import ProgressBar, { ProgressColor, ProgressSize } from '../../Shared/Components/ProgressBar';
import { PLATFORM } from '../../config';
import { useTranslation } from 'react-i18next';
import OnBoardingModal from './OnBoardingModal';
import WidgetLimitModel from './WidgetLimitModel';
import { isOpenedFromFlowBuilder, isOpenedFromFlowBuilderForSuperAdmin } from '../../Utils/Common';


const Layout = ({ children }) => {
  const { showProgressBar, bannerStatus, unifiedEditor, setRightBarLinks, isRightBarEmpty, setIsRightBarEmpty, setBannerStatus, setSelectedLink, isRequiredDataFilled, configSetting, systemSetting, previewSelectedSections, isMobileView, previewType, setSelectedWidgetType, isPositionChangeOfWidget, isShowWidgetLimitModal } = useContext(PageData);
  const [rightBarConfig, setRightBarConfig] = useState({});
  const [removedId, setRemoveId] = useState();
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();

  const handleClick = (e, data) => {
    if (isRequiredDataFilled()) {
      if (!e) {
        handleBack(ButtonEvent.Back);
      } else {
        const width = window.innerWidth;
        if (width <= 1614 && rightBarConfig?.widgetId !== data.widgetId) {
          setLoading(true);
        }
        setSelectedWidgetType(data.componentType);
        setRightBarConfig({
          componentType: data.componentType,
          count: data.count,
          minCount: data.minCount,
          blockType: data.blockType,
          id: data.id,
          widgetId: data.widgetId,
          parentWidgetId: data.parentWidgetId,
          target: data.target,
          parentWidgetType: data.parentWidgetType,
        });
      }
    }
  };

  const handleBack = useCallback((e) => {
    if (e === ButtonEvent.Back) {
      setRightBarConfig(prevState => ({ ...prevState, componentType: '' }));
      setRightBarLinks([]);
    }
  }, [setRightBarLinks, setRightBarConfig]);

  useEffect(() => {
    if (isRightBarEmpty) {
      setRightBarConfig(prevState => ({ ...prevState, componentType: '' }));
      setIsRightBarEmpty(false);
    }
  }, [isRightBarEmpty, setIsRightBarEmpty]);

  useEffect(() => {
    window.addEventListener('hidePreviewBanner', (e) => {
      setRemoveId(e.detail.id);
    });

    window.addEventListener('customeEvent', (e) => {
      setSelectedLink(e.detail);
    });

    return () => {
      window.removeEventListener('hidePreviewBanner', null);
      window.removeEventListener('customeEvent', null);
    }
  }, [setSelectedLink]);

  useEffect(() => {
    if (removedId) {
      let bannerSetting = bannerStatus;
      delete bannerSetting[removedId];
      setBannerStatus(bannerSetting);
      setRemoveId();
    }
  }, [removedId, setBannerStatus, bannerStatus]);

  const showHeader = useMemo(() => {
    return (PLATFORM === PlatformType.Wix || !isOpenedFromFlowBuilder() || isOpenedFromFlowBuilderForSuperAdmin());
  }, [PLATFORM, isOpenedFromFlowBuilder, isOpenedFromFlowBuilderForSuperAdmin]);

  return (
    <div style={{ maxHeight: '100vh', overflow: 'hidden' }} className={showHeader ? "wix-layout" : "shopify-layout"}>
      {showProgressBar && <ProgressBar size={ProgressSize.Small} color={ProgressColor.primary} />}
      {/* Header section of unified */}
      {showHeader && <Header data={unifiedEditor} /> }
      <div className='navigationWrapper'>
        <section className='main-layout'>
          <div className='main-layout-inner'>
            {/* Left section of unified */}
            <div
              className='left-action-menu'
            >
            </div>
            <div
              className={
                !rightBarConfig.componentType?.length
                  ? 'main-layout-left'
                  : 'main-layout-left sidebar-hide'
              }
            >
              <LeftSideBar onClick={handleClick} />
            </div>
            <div className={`main-layout-center ce-preview`}>

              <main className='center-section'>
                {children}
                {
                  <div>
                    {PLATFORM === PlatformType.Shopify &&
                      (<checkout-upsell-preview
                        value={JSON.stringify({
                          checkoutSchema: configSetting[configSetting.length - 1],
                          bannerStatus: bannerStatus,
                          selectedWidget: previewSelectedSections,
                          view: isMobileView ? 'Mobile' : 'Desktop',
                          currency: systemSetting?.currency,
                          currencySymbol: systemSetting?.currencySymbol,
                          platform: PLATFORM,
                          language: i18n.language,
                          previewType: previewType,
                          isPositionChangeOfWidget: isPositionChangeOfWidget,
                        })}
                      ></checkout-upsell-preview>)
                    }
                    {PLATFORM === PlatformType.Wix &&
                      (<wix-preview
                        value={JSON.stringify({
                          checkoutSchema: configSetting[configSetting.length - 1],
                          bannerStatus: bannerStatus,
                          selectedWidget: previewSelectedSections,
                          view: isMobileView ? 'Mobile' : 'Desktop',
                          currency: systemSetting?.currency,
                          currencySymbol: systemSetting?.currencySymbol,
                          platform: PLATFORM,
                          language: i18n.language,
                          previewType: previewType
                        })}
                      ></wix-preview>)
                    }
                  </div>
                }
              </main>
            </div>
            {/* Right section of unified */}
            <div
              className={
                rightBarConfig.componentType?.length
                  ? 'main-layout-left'
                  : 'main-layout-left sidebar-hide'
              }
            >
              <RightSideBar
                renderComponent={rightBarConfig}
                backButton={handleBack}
                loading={loading}
                setLoading={setLoading}
              />
            </div>
          </div>
        </section>
      </div>
      <HelpModal />
      {isShowWidgetLimitModal && <WidgetLimitModel/>}
      {PLATFORM === PlatformType.Wix && <OnBoardingModal />}
    </div>
  )

};

export default Layout;
