import React, { useContext, useCallback, useEffect } from 'react';
import '@shopify/polaris/build/esm/styles.css';
import { t } from 'i18next';

import { PageData } from '../../../ContextAPI/Context';
import { Toast_Message_Type } from '../../../Constant';
import ExitButton from './ExitButton';
import { Button } from '../../../Shared/Components';
import styles from './Header.module.css';

const Header = () => {
  const {
    configSetting,
    redoConfigSetting,
    submitData,
    setHelpModalData,
    requiredData,
    isRequiredDataFilled,
    helpLinks,
    onBoardingModalData,
    setOnBoardingModalData,
    setToastMessage,
  } = useContext(PageData);

  const handleSubmit = async () => {
    if (isRequiredDataFilled()) {
      let isDataSaved = await submitData();

      if (isDataSaved) {
        setToastMessage({
          message: 'Changes saved',
          messageType: Toast_Message_Type.Success
        });
      } else {
        setToastMessage({
          message: 'Server error',
          messageType: Toast_Message_Type.Error
        });
      }
    }
  };

  const handleLinkClick = () => {
    setHelpModalData(helpLinks.helpVideoButton);
  }

  const checkIsDisabled = useCallback(() => {
    if (configSetting.length < 2 && redoConfigSetting.length < 1) {
      return true;
    }
    return false
  }, [configSetting, redoConfigSetting])


  useEffect(() => {
    if (onBoardingModalData.isError) {
      setToastMessage({
        message: 'Something bad happen! Please try again',
        messageType: Toast_Message_Type.Error
      });
      setOnBoardingModalData({ ...onBoardingModalData, isError: false })
    }
  }, [onBoardingModalData, setOnBoardingModalData])

  return (
    <div className={styles.main_header}>
      <div className={styles.header_left_section}>
        <div className={styles.exit_button_container}>
          <ExitButton />
        </div>
      </div>
      <div className={styles.right_container}>
        <Button className={styles.help_button} onClick={handleLinkClick} color='teal' variant='outline' >{t('help_video')}</Button>
        <div className='px-3 custom-button'>
          <Button className={styles.save_button} onClick={handleSubmit} color='teal' disabled={requiredData.isDisable ? requiredData.isDisable : checkIsDisabled()} primary>
            {t('save')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Header;
