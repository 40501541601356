import React, { useContext } from 'react'
import { Modal } from '../../../Shared/Components'
import { PageData } from '../../../ContextAPI/Context';
import { t } from 'i18next';

const WidgetLimitModel = () => {
    const { setIsShowWidgetLimitModal, isShowWidgetLimitModal } = useContext(PageData);

    const handleClose = () => {
        setIsShowWidgetLimitModal(false);
    }
    
    return (
        <Modal
            rootStyles={{ height: '500px' }}
            active={isShowWidgetLimitModal}
            onSecondaryAction={handleClose}
            title={t('reached_sections_limit')}
            secondaryButtonLabel={t('ok')}
            content={t('reached_sections_limit_content')}
        />
    )
}

export default WidgetLimitModel;