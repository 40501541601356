import { Modal, TextContainer, Badge, Banner } from '@shopify/polaris';
import React from 'react';
import { t } from 'i18next';
import { Spinner } from '../../../../../../Shared/Components';
import { ThirdPartyApps } from '../../../../../../Constant';
import styles from './ThirdPartyAppModal.module.css'

const ThirdPartyAppModal = ({ isModalactive, setisModalActive, onCheckStatus, modalTitle, modalLogo, ismodalTitleShow, appInstallationUrl, selectedProduct, isAppInstalled, isAppIntegrated, loading }) => {
    if (!isModalactive) return null;

    const handleChange = (e) => {
        setisModalActive({ isActive: !isModalactive, isInstalled: e })
    };
    
    const handleAppInstall = (url) => {
        window.open(url) 
    };

    const activator = '';
    return (
        <div>
            <Modal
                activator={activator}
                open={isModalactive}
                onClose={() => handleChange(isAppInstalled)}
                title={
                    <div className={styles.titleContainer}>
                        <div className={styles.avatarImage}>
                            <img
                                src={"assets/shopify/images/" + modalLogo}
                                alt=""
                            />
                        </div>
                        <div>
                            {ismodalTitleShow && <span className={styles.titleText}>{modalTitle}</span>}
                            <Badge progress="complete" status={isAppInstalled ? "success" : "critical"}>{isAppInstalled ? "Installed" : "Not installed"}</Badge>
                        </div>
                    </div>}
                primaryAction={!isAppInstalled && {
                    content: 'Install ' + modalTitle,
                    disabled: appInstallationUrl?false:true,
                    onAction: () => handleAppInstall(appInstallationUrl),                   
                }}
                secondaryActions={isAppInstalled ? [
                    {
                        content: t('done'),
                        onAction: () => handleChange(true),
                    },
                ] : [
                    {
                        content: t('check_status'),
                        onAction: onCheckStatus,
                    },
                ]}
            >
                <Modal.Section>
                    <TextContainer>
                        {loading &&
                            <div className='spinner-icon'>
                                <Spinner size="large" />
                            </div>
                        }
                        {!loading && Object.keys(ThirdPartyApps).includes(selectedProduct) &&
                            (<React.Fragment>
                                {(!isAppInstalled || !isAppIntegrated) && <Banner status={"warning"} >{t(ThirdPartyApps[selectedProduct].text)}</Banner>}
                                <div dangerouslySetInnerHTML={{ __html: t(ThirdPartyApps[selectedProduct].description) }}></div>
                            </React.Fragment>)
                        }
                    </TextContainer>
                </Modal.Section>
            </Modal>
        </div>
    );
};
export default ThirdPartyAppModal;