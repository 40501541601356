import React from 'react';
import {
    Layout,
    LegacyCard,
    SkeletonBodyText,
    SkeletonDisplayText,
    VerticalStack,
} from '@shopify/polaris';
import { Skeleton as ChakraSkeleton, Stack } from '@chakra-ui/react';

import { PLATFORM } from '../../../config';
import { PlatformType } from '../../../Constant';

const Skeleton = () => {
    const arr = Array.from(Array(4).keys())
    return (
        <>
            {PLATFORM === PlatformType.Wix ?
                <Stack >
                    <ChakraSkeleton height='20px' width='50%' />
                    <ChakraSkeleton height='10px' />
                    <ChakraSkeleton height='10px' width='90%' />
                </Stack>
                :
                <Layout>
                    <Layout.Section secondary>
                        {arr.map((item, index) => {
                            return (
                                <LegacyCard.Section key={index}>
                                    <VerticalStack>
                                        <SkeletonDisplayText size='small' />
                                        <SkeletonBodyText lines={2} />
                                    </VerticalStack>
                                </LegacyCard.Section>
                            )
                        })}
                    </Layout.Section>
                </Layout>
            }
        </>
    );
}

export default Skeleton;