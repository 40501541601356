import React, { useContext, useEffect } from 'react';
import { t } from 'i18next';
import { PageData } from '../../../../../../ContextAPI/Context';
import { Label, Button, Banner } from '../../../../../../Shared/Components';
import { BannerType } from '../../../../../../Shared/Components/Banner';
import { PLATFORM } from '../../../../../../config';
import { PlatformType } from '../../../../../../Constant';

const CustomImageSelector = props => {
    const { requiredData, setRequiredData } = useContext(PageData)
    const { selectImage, setSelectImage, selectedFile, handleDelete, isRequired } = props;

    const handleImageChange = () => {
        document.querySelector('.right-section')?.classList.remove('overflow_vertical_scroll');
        document.querySelector('.bottom_container')?.classList?.add('removeDiv');
        document.querySelector('.right-section-title')?.classList?.add('removeDiv');
        setSelectImage(!selectImage);
    }
    
    useEffect(() => {
        if (!selectedFile && isRequired) {
            setRequiredData({ ...requiredData, stopChanges: true, toastMessage: 'You need to select an image', })
        }

        return () => {
            setRequiredData({ stopChanges: false, showErrorMessage: false, toastMessage: '' })
        }
    }, [selectedFile, isRequired]);

    return (
        <div className='image_component_holder'>
            {selectedFile ? (
                <>
                    <Label label='select_image' tooltipText='image' rootStyles={{ marginBottom: '4px' }} />
                    <div className='selected drop-upload-image-box'>
                        <div className='drop-upload-details'>
                            <img src={selectedFile.public_url} alt='selected file' />
                            <div className='selected-buttons'>
                                <Button color='gray' variant='outline' onClick={handleImageChange}>{t('change')}</Button>
                                <Button color='gray' variant='outline' onClick={handleDelete}>{t('delete')}</Button>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className={`${PLATFORM === PlatformType.Wix ? 'wix' : 'shopify'} select-image`}>
                    <Label label='select_image' tooltipText='image' rootStyles={{ marginBottom: '4px' }} />
                    <div className='select-image-box'>
                        <Button className='image-select-button' color='teal' fullWidth variant='outline' onClick={handleImageChange}>{t('select_image')}</Button>
                    </div>
                    {requiredData.showErrorMessage && isRequired &&
                        <div className='product-selector-banner'>
                            <Banner status={BannerType.Error}>{requiredData.toastMessage}</Banner>
                        </div>
                    }
                </div>
            )}
        </div>
    );
};

export default CustomImageSelector; 