import { ProgressBar as PolarisProgress } from '@shopify/polaris';
import { Progress as ChakraProgress } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import styles from './ProgressBar.module.css';
import { PLATFORM } from '../../../config';
import { PlatformType } from '../../../Constant';

export const ProgressSize = {
    Small: 'small',
    Medium: 'medium',
    Large: 'large',
}

export const ProgressColor = {
    primary: 'primary',
    highlight: 'highlight',
    critical: 'critical',
}

const ProgressSizeList = {
    large: {
        [PlatformType.Wix]: 'lg',
        [PlatformType.Shopify]: 'large'
    },
    medium: {
        [PlatformType.Wix]: 'md',
        [PlatformType.Shopify]: 'large'
    },
    small: {
        [PlatformType.Wix]: 'xs',
        [PlatformType.Shopify]: 'small'
    }
}

const ProgressColorList = {
    primary: {
        [PlatformType.Wix]: 'green',
        [PlatformType.Shopify]: 'primary'
    },
    highlight: {
        [PlatformType.Wix]: 'cyan',
        [PlatformType.Shopify]: 'highlight'
    },
    critical: {
        [PlatformType.Wix]: 'red',
        [PlatformType.Shopify]: 'critical'
    }
}

const ProgressBar = ({ size, color }) => {
    const [count, setCount] = useState(0);
    const progressSize = ProgressSizeList[size]?.[PLATFORM];
    const progressColor = ProgressColorList[color]?.[PLATFORM];

    useEffect(() => {
        for (let index = 0; index <= 1000; index++) {
            setCount(index/10);
        }
    }, []);

    return (
        <>
            {PLATFORM === PlatformType.Wix ?
                <ChakraProgress className={styles.progress_component} value={count} size={progressSize} colorScheme={progressColor} />
                :
                <div className={styles.progress_component_polaris}>
                    <PolarisProgress progress={count} color={progressColor} />
                </div>
            }
        </>
    )
}

export default ProgressBar