import { AspectRatio, Box, Container, Input, Stack, Text } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import styles from './ImageUpload.module.css'
import { ACCEPT_FILE_TYPE } from '.';
import { t } from 'i18next';

const ChakraImageUpload = ({ onUpload, disabled, multiple}) => {
    const controls = useAnimation();
    const startAnimation = () => controls.start('hover');
    const stopAnimation = () => controls.stop();
    return (
        <Container className={styles.image_container}  >
            <AspectRatio width='64' ratio={1} className={styles.group_container}>
                <Box
                    borderColor='gray.300'
                    borderStyle='dashed'
                    borderWidth='1px'
                    rounded='md'
                    shadow='sm'
                    role='group'
                    transition='all 150ms ease-in-out'
                    _hover={{
                        borderColor: 'blue.700'
                    }}
                    as={motion.div}
                    initial='rest'
                    animate='rest'
                    whileHover='hover'
                >
                    <Box position='relative' height='120px' width='120px'>
                        <Box
                            position='absolute'
                            top='0'
                            left='0'
                            height='120px'
                            width='120px'
                            display='flex'
                            flexDirection='column'
                        >
                            <Stack
                                height='120px'
                                width='120px'
                                display='flex'
                                alignItems='center'
                                justify='center'
                                spacing='4'
                            >
                                <Stack p='8' textAlign='center' spacing='1'>
                                    <Text className={styles.upload_button} fontWeight='light'>{t('upload')}</Text>
                                </Stack>
                            </Stack>
                        </Box>
                        <Input
                            type='file'
                            className={styles.input_container}
                            aria-hidden='true'
                            accept={ACCEPT_FILE_TYPE}
                            disabled={disabled}
                            onDragEnter={startAnimation}
                            onDragLeave={stopAnimation}
                            multiple={multiple}
                            onChange={(e) => { onUpload(e, e?.target?.files); }}
                        />
                    </Box>
                </Box>
            </AspectRatio>
        </Container>
    );
}

export default ChakraImageUpload;