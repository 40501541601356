import React, { useCallback, useContext, useEffect } from 'react'
import { t } from 'i18next';
import { Button, Icon, Switch, Toast } from '../../../../../Shared/Components';
import { IconSource } from '../../../../../Shared/Components/Icon';
import styles from './QuickActionBar.module.css';
import { PageData } from '../../../../../ContextAPI/Context';
import { CreatedNewObj } from '../../../../../Utils/Common';
import { eventsName, PlatformType, Toast_Message_Type } from '../../../../../Constant';
import { PLATFORM } from '../../../../../config';
import { toUpper } from 'lodash';

const QuickActionBar = () => {
    const { configSetting, updateData, redoConfigSetting, undoFun, redoFun, isRequiredDataFilled, requiredData, latestConfigSetting, setIsMobileView, isMobileView, setHelpModalData, helpLinks, setToastMessage, toastMessage, submitData, setConfigSetting,
        setRedoConfigSetting, setIsRightBarEmpty, sendPostMessage, setRequiredData } = useContext(PageData);

    const handleToggle = (e) => {
        if (isRequiredDataFilled()) {
            let value = e.target.checked;
            let updateSettings = CreatedNewObj(latestConfigSetting);
            updateSettings = { ...updateSettings, status: value }
            updateData(updateSettings);
        }
    };

    const handleChange = useCallback(() => {
        setIsMobileView(!isMobileView);
    }, [isMobileView]);

    const isPageLive = useCallback(() => {
        return latestConfigSetting?.status;
    }, [latestConfigSetting]);

    const checkIsDisabled = useCallback(() => {
        if (configSetting.length < 2 && redoConfigSetting.length < 1) {
            return true;
        }
        return false
    }, [configSetting, redoConfigSetting])

    const handleSubmit = useCallback(async () => {
        if (isRequiredDataFilled()) {
            const isDataSaved = await submitData(latestConfigSetting);

            if (isDataSaved) {
                setToastMessage({
                    message: 'Changes saved',
                    messageType: Toast_Message_Type.Success
                });
            } else {
                setToastMessage({
                    message: 'Server error',
                    messageType: Toast_Message_Type.Error
                });
            }
        }
    }, [latestConfigSetting, isRequiredDataFilled, setToastMessage, submitData]);

    useEffect(() => {
        if (window.parent) {
            if(!(requiredData.isDisable ? requiredData.isDisable : checkIsDisabled())) {
                sendPostMessage("show-save-bar");
            }
        }
    }, [requiredData, checkIsDisabled]);

    useEffect(() => {
        const handleMessage = async (event) => {
            if (event?.data?.page !== "flow-editor") return false;

            switch (event.data.event) {
                case eventsName.HelpVideoShow:
                    setHelpModalData(helpLinks.helpVideoButton); // Set help video button link
                    break;
                case eventsName.HandleSave, eventsName.SaveForm:
                    handleSubmit(); // When user save changes, show success toast message
                    break;
                case eventsName.DiscardForm:
                    sendPostMessage("hide-save-bar"); // When user discard changes, hide unsaved bar   
                    setConfigSetting([configSetting[0]]); // when user discard changes, clear new changes
                    setRedoConfigSetting([]); // when user discard changes, clear undo redo config
                    setIsRightBarEmpty(true); // When user discard changes, clear right side bar
                    break;
            }
        };

        window.addEventListener("message", handleMessage); // This event listener will listen for messages only for shopify.

        return () => window.removeEventListener("message", handleMessage);
    }, [helpLinks, handleSubmit]);

    useEffect(() => {
        if (requiredData.showToast) {
            setToastMessage({
                message: requiredData.toastMessage || 'You need to select a product',
                messageType: Toast_Message_Type.Error
            });
            setRequiredData({ ...requiredData, showToast: false });
        }

    }, [requiredData.showToast]);

    return (
        <>
            <div className={styles.main_text}> {PLATFORM === PlatformType.Wix ? toUpper(t('quick_action')) : t('quick_action')} </div>
            <div id={styles.quick_action_bar}>
                <div className={styles.nav_view_button}>
                    <Button onClick={undoFun} disabled={configSetting.length <= 1} plain>
                        <Icon source={IconSource.Undo} iconAlt={'exit_button'} >
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.action_button}>
                                <path d="M12.5 8.5C9.85 8.5 7.45 9.49 5.6 11.1L2 7.5V16.5H11L7.38 12.88C8.77 11.72 10.54 11 12.5 11C16.04 11 19.05 13.31 20.1 16.5L22.47 15.72C21.08 11.53 17.15 8.5 12.5 8.5Z" fill="black" />
                            </svg>
                        </Icon>
                    </Button>
                </div>
                <div className={styles.nav_view_button}>
                    <Button onClick={redoFun} disabled={redoConfigSetting.length < 1} plain>
                        <Icon source={IconSource.Redo} iconAlt={'exit_button'} >
                            <svg width="21" height="10" viewBox="0 0 21 10" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.action_button}>
                                <path d="M17.4 4.1C15.55 2.49 13.15 1.5 10.5 1.5C5.85004 1.5 1.92004 4.53 0.540039 8.72L2.90004 9.5C3.42672 7.89999 4.44489 6.507 5.80951 5.51945C7.17412 4.5319 8.81557 4.00017 10.5 4C12.45 4 14.23 4.72 15.62 5.88L12 9.5H21V0.5L17.4 4.1Z" fill="black" />
                            </svg>
                        </Icon>
                    </Button>
                </div>
                {isMobileView ?
                    <div className={styles.nav_view_button}>
                        <Button onClick={handleChange} plain>
                            <Icon source={IconSource.Mobile} iconAlt={'mobile'} >
                            </Icon>
                        </Button>
                    </div>
                    :
                    <div className={styles.nav_view_button}>
                        <Button onClick={handleChange} plain>
                            <Icon iconName={"desktop.svg"} iconAlt={'desktop'} >
                            </Icon>
                        </Button>
                    </div>
                }
                <div className={styles.header_switch_container}>
                    {PLATFORM === PlatformType.Wix && <span className={styles.switch_span}>{isPageLive() ? t('on') : t('off')}</span>}
                    <Switch
                        isOn={isPageLive()}
                        handleToggle={handleToggle}
                    />
                </div>
            </div>
            <div className={styles.main_text}>
                {PLATFORM === PlatformType.Wix ? toUpper(t('order_summary')) : latestConfigSetting?.name}
            </div>
            <Toast
                message={toastMessage.message}
                messageType={toastMessage.messageType}
                onDismiss={(() => setToastMessage({ message: '', messageType: '' }))}
            />
        </>
    )
}

export default QuickActionBar;