import React, { useContext } from 'react';
import { t } from 'i18next';
import { PageData } from '../../../../../ContextAPI/Context';
import { ThreeSectionLayout } from '../../../../../Constant';

const RightBarSectionLinks = ({ rightBarComponentType, widgetId }) => {
  const { rightBarLinks, isRequiredDataFilled, setSelectedLink } = useContext(PageData);

  const handleLinkClick = (obj) => {
    if (isRequiredDataFilled()) {
      setSelectedLink(obj);
    }
  }

  return (
    <>
      { rightBarLinks && rightBarLinks.length > 0 &&
        <div className='links-container'>
          {!ThreeSectionLayout.includes(rightBarComponentType) && <div className='link-divider' />}
          <div className='link-title'>{!widgetId ? t(`edit_widgets_in_this_widget`) : t(`edit_blocks_in_this_widget`)}</div>
          {rightBarLinks.map((item, index) => {
            return <div key={index} className='link-content' onClick={() => handleLinkClick(item)}>{item.name}</div>
          })}
        </div>
      }
    </>
  )
}

export default RightBarSectionLinks;