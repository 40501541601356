import React, { useCallback, useState, useContext, useEffect, useMemo } from 'react';
import { cloneDeep, isArray } from 'lodash';
import { t } from 'i18next';
import { PageData } from '../../../../../ContextAPI/Context';
import { fetchProductImages, uploadProductImages } from '../../../../../Service';
import { CreatedNewObj, checkTargetConditions, getSelectedWidget, getTargetedValue, updateTargetedValue } from '../../../../../Utils/Common';
import { ComponentType } from '../../../../../Constant';
import CustomImageSelector from './components/CustomImageSelector';
import RenderComponent from '../Shared/RenderComponent';
import { Header, ImageUpload, Button, Icon } from '../../../../../Shared/Components';
import { IconSource } from '../../../../../Shared/Components/Icon';

const ImageSelector = (props) => {
    const [selectImage, setSelectImage] = useState(false);
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState();
    const [selectingFile, setSelectingFile] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const { configSetting, unifiedEditor, updateData, previewType, isMobileView } = useContext(PageData);
    const [widgetSettings, setWidgetSettings] = useState([]);

    const { id, widgetId, parentWidgetId, target, componentType, blockType } = props.type;

    const latestSettings = useMemo(() => {
        return cloneDeep(configSetting[configSetting.length - 1]);
    }, [configSetting]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await fetchProductImages();
                setFiles([...res.data]);
            } catch (e) { console.warn('Image fetch error', e); }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const targetedWidget = getTargetedValue(latestSettings, target, widgetId, parentWidgetId, blockType, id);
        const selectedWidget = getSelectedWidget(targetedWidget, unifiedEditor.widgets, previewType);
        setWidgetSettings(selectedWidget);
    }, [latestSettings, target, widgetId, parentWidgetId, unifiedEditor.widgets, blockType, id]);

    useEffect(() => {
        if (!widgetSettings || !widgetSettings.blockSettings || !configSetting || configSetting.length === 0) {
            return;
        }

        const ts = widgetSettings.blockSettings.find(setting => setting.type === ComponentType.Selector);
        const updatedVal = getTargetedValue(latestSettings, `${target}.${ts?.target}`, widgetId, parentWidgetId, blockType, id);

        if (!updatedVal || updatedVal === ' ') {
            setSelectedFile();
        } else {
            setSelectedFile({ public_url: updatedVal });
        }
    }, [latestSettings, configSetting, target, widgetId, parentWidgetId, blockType, id, widgetSettings]);

    const handleUpload = useCallback(async (_droppedFiles, acceptedFiles) => {

        setIsDisabled(true);
        let acceptedFilesList = acceptedFiles;
        if (!isArray(acceptedFiles)) {
            acceptedFilesList = Object.values(acceptedFiles);
        }
        setFiles(files => [...files, ...acceptedFilesList.map(() => ({ loading: true }))]);

        try {
            const savedFiles = await uploadProductImages(acceptedFilesList);
            setFiles(files => [...files.filter(file => !file.loading), ...savedFiles.data]);
        } catch (event) {
            console.warn('Image upload error', event);
        }
        setIsDisabled(false);
    }, [files]);

    const uploadedFiles = files.length > 0 && (
        files.map((file, index) => {
            const isSelected = selectingFile != null && selectingFile.public_url === file.public_url
            return (
                <div onClick={() => setSelectingFile(file)} key={index}
                    className={'product-img ' + (isSelected ? 'selected-img ' : '') + (file.loading ? 'loading' : '')}>
                    {file.loading ? <img src='assets/spinner.svg' alt='loading' /> : (
                        <>
                            <img src={file.public_url} alt='selected file' />
                            <span className='tick-icon'>
                                {isSelected && <Icon source={IconSource.Tick} iconAlt={'tick'} />}
                            </span>
                        </>
                    )}
                </div>
            )
        })
    );

    const handleSelectImage = (file) => {
        const targetSettings = widgetSettings.blockSettings.find(setting => setting.type === ComponentType.Selector);
        let newTarget = `${target}.${targetSettings.target}`;
        const cloneSettings = CreatedNewObj(latestSettings);
        let updatedSettings = updateTargetedValue(cloneSettings, newTarget, file.public_url, widgetId, parentWidgetId, blockType, id);

        updateData(updatedSettings);

        setSelectedFile(file);
        setSelectingFile(null);
        setSelectImage(false);
        document.querySelector('.right-section')?.classList.add('overflow_vertical_scroll');
        document.querySelector('.bottom_container')?.classList?.remove('removeDiv');
        document.querySelector('.right-section-title')?.classList?.remove('removeDiv');
    }

    const handleCancel = useCallback(() => {
        document.querySelector('.right-section')?.classList.add('overflow_vertical_scroll');
        document.querySelector('.bottom_container')?.classList?.remove('removeDiv');
        document.querySelector('.right-section-title')?.classList?.remove('removeDiv');
        setSelectImage(!selectImage);
    }, [selectImage]);

    useEffect(() => {
        return () => handleCancel();
    }, [componentType, handleCancel]);

    const handleDelete = () => {
        const targetSettings = widgetSettings.blockSettings.find(setting => setting.type === ComponentType.Selector);
        let newTarget = `${target}.${targetSettings.target}`;
        let defaultProductImage = '';
        const cloneSettings = CreatedNewObj(latestSettings);
        let updatedSettings = updateTargetedValue(cloneSettings, newTarget, defaultProductImage, widgetId, parentWidgetId, blockType, id);

        updateData(updatedSettings);
        setSelectedFile(null);
    }
    try {
        return (
            <>
                {!selectImage ?
                    (
                        <div>
                            <div className='image-section-container image-fields-wrap'>
                                <Header label={'image'} showHelpLink/>
                                {widgetSettings?.blockSettings === undefined ?
                                    null
                                    :
                                    widgetSettings?.blockSettings?.map((setting, index) => {
                                        if (setting.conditional && setting.type === ComponentType.Selector && checkTargetConditions({widgets: latestSettings, target: `${target}.${setting.target}`, conditions: setting.condition, widgetId: widgetId, parentWidgetId: parentWidgetId, _type: blockType, id: id, isMobileView})) {
                                            return (
                                                <div key={index}>
                                                    <CustomImageSelector selectImage={selectImage} setSelectImage={setSelectImage} selectedFile={selectedFile} setSelectedFile={setSelectedFile} handleDelete={handleDelete} isRequired={setting?.isRequired} />
                                                </div>
                                            )
                                        } else if ((setting.conditional && checkTargetConditions({widgets: latestSettings, target: `${target}.${setting.target}`, conditions: setting.condition, widgetId: widgetId, parentWidgetId: parentWidgetId, _type: blockType, id: id, isMobileView})) || !setting.conditional) {
                                            return (
                                                <div key={index}>
                                                    <RenderComponent data={{ setting, id, widgetId, parentWidgetId, target, componentType: blockType }} />
                                                </div>
                                            )
                                        } else {
                                            return null;
                                        }
                                    })}
                            </div>
                        </div>
                    )
                    : (
                        <div>
                            <Header label={!selectImage ? 'image' : 'images'} onClose={() => handleCancel()} rootStyles={{ padding: '0.5rem 1rem' }} dividerStyles={{ margin: '0px' }} />
                            <div className='image-section-container image-selector-wrap'>
                                <div className='images-container product-image-list'>
                                    <div className='product-img'>
                                        <ImageUpload onUpload={handleUpload} disabled={isDisabled} multiple={true} />
                                    </div>
                                    {uploadedFiles}
                                </div>
                                <div className='product-bottom-container'>
                                    <div className='image-buttons custom-button'>
                                        <Button color='gray' variant='outline' onClick={handleCancel}>{t('cancel')}</Button>
                                        <Button color='teal' variant='solid' primary disabled={!selectingFile} onClick={() => handleSelectImage(selectingFile)}>{t('select')}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

            </>
        );
    } catch (err) {
        return (
            <div className='m-5 text-center text-secondary'>Something went wrong..</div>
        )
    }
};

export default ImageSelector;
