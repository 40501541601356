import { useState, useCallback, useEffect, useContext } from 'react';
import { t } from 'i18next';
import { PageData } from '../../../ContextAPI/Context';
import { Modal } from '../../../Shared/Components';
import ComponentStyles from './ComponentStyle';

const HelpModal = () => {
    const [active, setActive] = useState(false);
    const { helpModalData, setHelpModalData } = useContext(PageData);
    const [isFrameLoad, setIsFrameLoad] = useState(false);

    useEffect(() => {
        if (helpModalData?.link) {
            setActive(true);
        }
    }, [helpModalData]);

    const handleClose = useCallback(() => {
        setHelpModalData({});
        setActive(false);
    }, [setHelpModalData]);

    const handleLoad = () => {
        setIsFrameLoad(true)
    }
    return (
        <div>
            <ComponentStyles active={active} isFrameLoad={isFrameLoad}/>
            <Modal
                hideClose
                title={t(helpModalData?.title)}
                activator={''}
                active={active}
                onSecondaryAction={handleClose}
                secondaryButtonLabel={t('close')}
                src={helpModalData?.link}
                onIFrameLoad={handleLoad}
            />
        </div>
    );
}

export default HelpModal