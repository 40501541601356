import React from 'react';
import Tooltip from '../Tooltip';
import styles from './Label.module.css';
import { t } from 'i18next';

const Label = ({ label, tooltipText, showTooltip, rootStyles }) => {
    return (
        <div className={styles.label_container} style={{ ...rootStyles }}>
           <label>{t(label)}</label>
           {showTooltip && <Tooltip tooltipText={t(tooltipText)}/>}
        </div>
    )
}

export default Label;