import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import RenderComponent from '../RenderComponent';
import { Button, Icon } from '../../../../../../Shared/Components';
import styles from './GroupContainer.module.css';
import { t } from 'i18next';
import { getTargetedValue } from '../../../../../../Utils/Common';
import { PageData } from '../../../../../../ContextAPI/Context';
import { WidgetType } from '../../../../../../Constant';
import { IconSource } from '../../../../../../Shared/Components/Icon';

const GroupContainer = (props) => {
    const { setting, value, widgetId, onChange, parentWidgetId, componentType, id, target } = props;
    const { latestConfigSetting, unifiedEditor } = useContext(PageData);

    const defaultValue = useMemo(() => {
        // Get the default value from the defaultSections object for the selected widget or block.
        const selectedWidget = getTargetedValue(latestConfigSetting, target, widgetId, parentWidgetId, componentType, id);
        let selectedData = unifiedEditor.defaultSections.find(section => section.type === selectedWidget.type);
        // eslint-disable-next-line
        if (!!id) {
            // select the block from the upsell widget.
            const upsellWidget = unifiedEditor.defaultSections.find(section => section.type === WidgetType.Upsell);
            selectedData = upsellWidget.blocks.find(block => block.type === componentType);
        }
        // return the default value from the defaultSections object for the selected widget or block.
        return getTargetedValue(selectedData, setting.groups.defaultTarget, widgetId, parentWidgetId, componentType, id);
    }, [latestConfigSetting, target, widgetId, parentWidgetId, componentType]);

    useEffect(() => {
        //If the number of group is depended on another widget and the number of group is changed, the number of group should be adjusted accordingly.
        if (setting?.dependedTarget) {
            const numberOfGroup = getTargetedValue(latestConfigSetting, `${target}.${setting.dependedTarget}`, widgetId, parentWidgetId, componentType) || 1;
            if (numberOfGroup > value?.length) {
                // adding new group
                const newGroup = [...value, ...Array(numberOfGroup - value.length).fill(0).map(() => defaultValue)];
                onChange([...newGroup]);
            } else if (numberOfGroup < value?.length) {
                // removing group from the end
                value.splice(value.length - 1, numberOfGroup);
                onChange([...value]);
            }
        }
    }, [latestConfigSetting, setting, widgetId, parentWidgetId, componentType, target]);

    const addNewGroup = useCallback(() => {
        const newGroup = [...value, defaultValue];
        onChange([...newGroup]);
    }, [setting, value]);

    const removeGroup = useCallback((index) => {
        onChange(value.filter((_, i) => i !== index));
    }, [value]);

    return (
        <>
            {value && value.length && value.map((_, index) => {
                return (
                    <>
                        <div className={styles.remove_button}>
                            {setting?.groups?.label && (
                                <span>{`${t(setting?.groups.label)} ${index + 1}`}</span>
                            )}
                            {setting?.groups?.showAddRemoveButton && (
                                <>
                                    {index >= 1 && (
                                        <div onClick={() => removeGroup(index)}>
                                            <Icon source={IconSource.Delete} iconAlt={'remove'} >
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.16667 16.6666C4.16667 17.1087 4.34226 17.5326 4.65482 17.8451C4.96738 18.1577 5.39131 18.3333 5.83333 18.3333H14.1667C14.6087 18.3333 15.0326 18.1577 15.3452 17.8451C15.6577 17.5326 15.8333 17.1087 15.8333 16.6666V6.66663H17.5V4.99996H14.1667V3.33329C14.1667 2.89127 13.9911 2.46734 13.6785 2.15478C13.3659 1.84222 12.942 1.66663 12.5 1.66663H7.5C7.05797 1.66663 6.63405 1.84222 6.32149 2.15478C6.00893 2.46734 5.83333 2.89127 5.83333 3.33329V4.99996H2.5V6.66663H4.16667V16.6666ZM7.5 3.33329H12.5V4.99996H7.5V3.33329ZM6.66667 6.66663H14.1667V16.6666H5.83333V6.66663H6.66667Z" fill="#C53030" />
                                                    <path d="M7.5 8.33337H9.16667V15H7.5V8.33337ZM10.8333 8.33337H12.5V15H10.8333V8.33337Z" fill="#C53030" />
                                                </svg>
                                            </Icon>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        {setting?.groups?.settingGroups.map((nestedSetting, subIndex) => {
                            return (
                                <React.Fragment key={subIndex}>
                                    <RenderComponent data={{ setting: nestedSetting, id, widgetId, parentWidgetId, target, componentType: componentType, syncSettings: false, index: index }} />
                                </React.Fragment>
                            )
                        })}
                    </>
                )
            })}
            {setting?.groups?.showAddRemoveButton && (
                <div className={styles.add_button}>
                    <Button onClick={addNewGroup} fullWidth >{t(setting.groups.addButtonText)} </Button>
                </div>
            )}
        </>
    )
}

export default GroupContainer
