import React, { useState, useCallback, useMemo } from 'react';
import cn from 'clsx';
import {
  LegacyStack,
  Tag,
  Listbox,
  Combobox,
  Text,
} from '@shopify/polaris';
import styles from './TagInput.module.css';

function TagInputShopify({ onChange, onRemove, tags, placeholder }) {
  const [value, setValue] = useState('');
  const [suggestion, setSuggestion] = useState('');
  const [isAutoFocus, setIsAutoFocus] = useState(false);

  const rootClassName = cn(styles.tag_container, tags.length > 0 && styles.populated);

  const handleChange = useCallback((e) => {
    setValue('');
    setSuggestion('');
    setIsAutoFocus(true);
    onChange(e);
  }, [onChange]);

  const handleActiveOptionChange = useCallback(
    (activeOption) => {
      const activeOptionIsAction = activeOption === value;

      if (!activeOptionIsAction && !tags.includes(activeOption)) {
        setSuggestion(activeOption);
      } else {
        setSuggestion('');
      }
    }, [value, tags]);

  const formatOptionText = useCallback(
    (option) => {
      const trimValue = value?.trim().toLocaleLowerCase();
      const matchIndex = option.toLocaleLowerCase().indexOf(trimValue);

      if (!value || matchIndex === -1) return option;

      const start = option.slice(0, matchIndex);
      const highlight = option.slice(matchIndex, matchIndex + trimValue.length);
      const end = option.slice(matchIndex + trimValue.length, option.length);

      return (
        <p>
          {start}
          <Text as='span' fontWeight='semibold'>{highlight}</Text>
          {end}
        </p>
      );
    }, [value]);

  const verticalContentMarkup =
    tags.length > 0 ? (
      <div className={styles.tags_wrap}>
        <LegacyStack spacing='extraTight' alignment='center'>
          {tags.map((tag) => (
            <Tag key={`option-${tag}`} onRemove={() => onRemove(tag)}>
              {tag}
            </Tag>
          ))}
        </LegacyStack>
      </div>
    ) : null;

  const options = useMemo(() => {
    let list;
    const allTags = [...new Set([...tags].sort())];
    const filterRegex = new RegExp(value, 'i');

    if (value) {
      list = allTags.filter((tag) => tag.match(filterRegex));
    } else {
      list = allTags;
    }

    return [...list];
  }, [value, tags]);

  const optionMarkup =
    options.length > 0
      ? options.map((option) => {
        return (
          <Listbox.Option
            key={option}
            value={option}
            selected={tags.includes(option)}
            accessibilityLabel={option}
          >
            <Listbox.TextOption selected={tags.includes(option)}>
              {formatOptionText(option)}
            </Listbox.TextOption>
          </Listbox.Option>
        );
      })
      : null;

  const noResults = useMemo(() => {
    return value && !tags.includes(value);
  }, [value, tags]);

  const actionMarkup = noResults ? (
    <Listbox.Action value={value}>{`Add '${value}'`}</Listbox.Action>
  ) : null;

  return (
    <div className={`${rootClassName} ${styles.tag_input_wrap}`}>
      <Combobox
        allowMultiple
        activator={
          <Combobox.TextField
            autoComplete='off'
            label='Add tags'
            labelHidden
            value={value}
            suggestion={suggestion}
            placeholder={placeholder}
            verticalContent={verticalContentMarkup}
            onChange={setValue}
            autoFocus={isAutoFocus}
          />
        }
      >
        <Listbox
          onSelect={handleChange}
          onActiveOptionChange={handleActiveOptionChange}
        >
          {actionMarkup}
          {optionMarkup}
        </Listbox>
      </Combobox>
    </div>
  );
}

export default TagInputShopify;
